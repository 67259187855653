import "./RightSideBar.css";

import { IIconButtonProps, IconButton } from "../../../lib/components/buttons/IconButton";
import { useEffect, useMemo } from "react";

import { CommentService } from "../../../features/documentation/services/CommentService";
import { MenuItem } from "../../../lib/components/side-bar/MenuItem";
import { SettingsSVG } from "../../../lib/assets/icons";
import { SideBarButton } from "../../../lib/components/side-bar/SideBarButton";

interface IRightSideBarProps {
  className?: string;
  buttons: IRightSideBarButtons[];
}

export interface IRightSideBarButtons {
  icon: React.ReactNode;
  onClick: () => void; 
  isActive: boolean;
}

export function RightSideBar(props: IRightSideBarProps) {
  useEffect(() => {}, []);

  const rightSideBarTopButtons = useMemo(() => {
    return props.buttons.map((m, idx) => {
      return (
        <IconButton
          key={idx}
          icon={m.icon}
          onClick={m.onClick}
          className="button-height"
          isActive={m.isActive}
        ></IconButton>
      );
    });
  }, [props.buttons]);

  return (
    <div className="right-side-bar">
      <div className="right-side-bar-main">{rightSideBarTopButtons}</div>
    </div>
  );
}
