import { EyeSVG, FileSVG, QuestionSVG, SettingsSVG } from "../lib/assets/icons";
import { Navigate, Route, Routes, To, useLocation, useParams } from "react-router-dom";

import { Comments } from "../features/documentation/pages/documentation-home-page/comments/Comments";
import { DocumentationPage } from "../features/documentation/pages/documentation-home-page/DocumentationPage";
import { DoubtsPage } from "../features/documentation/pages/documentation-home-page/gpt/DoubtsPage";
import { EditSpaceModule } from "./EditSpaceModule";
import { Helmet } from "react-helmet";
import { MenuItem } from "../lib/components/side-bar/MenuItem";
import { Sidebar } from "../lib/components/side-bar/Sidebar";
import { SpaceOverview } from "../features/spaces/pages/spaces-home-page/overview/SpaceOverview";
import { SpaceService } from '../features/spaces/services/SpaceService';
import { useEffect } from "react";
import { useServiceCallPro } from "../common/services/UseServiceCall";
import { useSetSelectedSpace } from '../lib/infrastructure/ui/UIServices';

const sideBarTopButtons: MenuItem[] = [
  {
    icon: <EyeSVG />,
    navigateTo: "overview",
  },
  {
    icon: <FileSVG />,
    navigateTo: "pages",
  },
  {
    icon: <QuestionSVG />,
    navigateTo: "gpt",
  },
];

const sideBarBottomButtons: MenuItem[] = [
  {
    icon: <SettingsSVG />,
    navigateTo: "edit/",
  },
];
var spaceSvc = new SpaceService();


export function SpacesModule() {



  const [selectedSpace, setSelectedSpace] = useSetSelectedSpace();
   const { spaceId } = useParams();

   const getByIdServiceCall = useServiceCallPro(spaceSvc.getById);



    useEffect(() => {
        if (spaceId && spaceId != selectedSpace?.id) {
          getByIdServiceCall.invoke(spaceId).then((res) => {
              setSelectedSpace({ id: spaceId, name: res.title });
            });
        }
    }, [spaceId]);

    useEffect(() => {

      return () => setSelectedSpace(undefined);
    }, [])

  return (
    <div className="main-content">
      <div className="sidebar-content">
        <Sidebar
          buttonsTop={sideBarTopButtons}
          buttonsBottom={sideBarBottomButtons}
        />
      </div>

      <div className="content">
        {!getByIdServiceCall.isLoading && selectedSpace?.name ?
          <Helmet>
            <title>{selectedSpace?.name} | Ark</title>
            <meta name="description" content={`Space: ${selectedSpace?.name}`} />
          </Helmet>
        : null}
        <Routes>
          <Route index element={<Navigate to="pages" replace/>} />
          <Route path="overview" element={<SpaceOverview />} />
          <Route path="pages" element={<DocumentationPage />} />
          <Route path="gpt" element={<DoubtsPage spaceId={spaceId!} />} />
          <Route path="pages/:pageId" element={<DocumentationPage />} />
          <Route path="edit/*" element={<EditSpaceModule />} />
          <Route path="pages/:pageId/comments" element={<Comments />} />
        </Routes>
      </div>
    </div>
  );
}
