import "./PageContentRenderer.css";

import { useMemo, useRef, useState } from "react";

import { AstroMarkCompiler } from "../../utils/AstroMarkCompiler";
import { CssClassNameBuilder } from "../../../../lib/utils/CssClassNameBuilder1";
import { EmptyState } from "../../../../lib/components/states/EmptyState";
import { Toc } from "../../../../lib/components/nav/toc/Toc";

export interface IPageContentRendererProps {
  hideToc: boolean;
  markdownContent?: string;
  className?: string;
  componentRef?: React.RefObject<HTMLDivElement>;
  markdownDivRef?: React.RefObject<HTMLDivElement>;
  emptyPageOnClick?: () => void;
}



export function PageContentRenderer(props: IPageContentRendererProps) {

  const pageContentRendererCss = useMemo(() => {
    return CssClassNameBuilder.new()
      .add("page-content-renderer")
      .add(props.className)
      .build();
  }, [props.className]);

  const toc = useMemo(() => AstroMarkCompiler.generateTocAst(props.markdownContent).children, [props.markdownContent])

  const html = useMemo(() => ({ __html: AstroMarkCompiler.compileToHtml(props.markdownContent)}), [props.markdownContent])

  return (

    <div className={pageContentRendererCss} ref={props.componentRef} >

      {props.markdownContent ? (
        <div className ="page-content-renderer-area">

          {!props.hideToc && toc && toc.length > 1 ?  <Toc items={toc}/> : null }
          <div className="page-content-renderer-body"
          ref={props.markdownDivRef} dangerouslySetInnerHTML={html} ></div>

        </div>

      ) : (
        <EmptyState buttonLabel="Edit Page" onClick={props.emptyPageOnClick} text={"This page is empty! Try add some content"}/>
      )}
    </div>
  );
}
