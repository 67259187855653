import './EditSpaceModule.css';

import { Navigate, Route, Routes } from "react-router-dom";

import { EditSpacePage } from "../features/spaces/pages/edit-space-page/EditSpacePage";
import { PermissionPage } from "../features/spaces/pages/permissions-page/PermissionsPage";

export function EditSpaceModule() {
  return (

    <div className="edit-content">
        <div className='edit-space-side-bar'>
          Settings
          <div className="edit-overview">Edit Overview</div>
          <div className="edit-permissions">Permissions</div>
        </div>

        <div className="edit-content-page-area">
        <Routes>
            <Route index element={<Navigate to="space" />} />
            <Route path="space" element={<EditSpacePage />} />
            <Route path="permission" element={<PermissionPage />} />
        </Routes>
        </div>

    </div>
  );
}
