import "./PageHistoryPopup.css";
import "react-markdown-editor-lite/lib/index.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useClosePopup,
  usePopup,
} from "../../../../../lib/infrastructure/ui/UIServices";

import { AstroMarkCompiler } from "../../../utils/AstroMarkCompiler";
import { CloseSVG } from "../../../../../lib/assets/icons";
import { CssClassNameBuilder } from "../../../../../lib/utils/CssClassNameBuilder1";
import { DateTimeHelper } from "../../../../../lib/utils/DateTimeHelper";
import { EmptyDocumentIllustrationSVG } from "../../../../../lib/assets/ilustrations";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../../../lib/layouts/containers/flex/FlexLayout";
import { LineSeparator } from "../../../../../lib/components/separator/LineSeparator";
import MarkdownIt from "markdown-it";
import { PageHistoryResponseDTO } from "../../../models/services/PageHistoryResponseDTO";
import { PageService } from "../../../services/PageService";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";

export interface IPageHistoryProps {
  pageId: string;
  spaceId: string;
}

const pagesSvc = new PageService();



function parsedSelectedVersionContent(content: string) {
  return (
    <div
      className="page-content-render markdown"
      dangerouslySetInnerHTML={{ __html: AstroMarkCompiler.compileToHtml(content) }}
    ></div>
  );
}

interface IHistoryContent {
  createdAt: string;
  oldTitle: string;
  oldContent: string;
  newTitle: string;
  newContent: string;
  authorName: string;
  key: number;
}

function mapToHistoryContent(values: PageHistoryResponseDTO[]) {
  var historiesMapped: IHistoryContent[] = values.map((r, idx) => {
    var hstMapped: IHistoryContent = {
      key: idx,
      newContent: r.newContent,
      newTitle: r.newTitle,
      oldContent: r.oldContent,
      oldTitle: r.oldTitle,
      createdAt: r.createdAt,
      authorName: r.authorName,
    };
    return hstMapped;
  });
  return historiesMapped;
}

export function PageHistoryPopup(props: IPageHistoryProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const [historyContents, setHistoryContents] = useState<IHistoryContent[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<number>(0);

  /****************************
   * DATA REQUESTS
   *****************************/

  useEffect(() => {
    if (props.spaceId && props.pageId) {
      pagesSvc
        .getPageHistory(props.spaceId, props.pageId)
        .then((result) => {
          var mappedHistory = mapToHistoryContent(result);
          setHistoryContents(mappedHistory);
        })
        .catch((error) => {
          openPopup(<ErrorPopup>{error.code}</ErrorPopup>);
        });
    }
  }, []);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  /****************************
   * USER ACTIONS
   *****************************/

  const handleVersionSelected = useCallback(
    (
      ev: React.MouseEvent<HTMLDivElement, MouseEvent> | null,
      historyContentId: number
    ) => {
      setSelectedVersion(historyContentId);
    },
    [selectedVersion]
  );

  /****************************
   * CSS & HTML
   *****************************/

  const historyVersions = useMemo(() => {
    return historyContents.map((m) => {
      const isSelected = selectedVersion === m.key;

      const css = CssClassNameBuilder.new()
        .add("version")
        .addConditional(isSelected, "selected")
        .addConditional(selectedVersion !== m.key, "hover")
        .build();

      return (
        <div
          className={css}
          key={m.key}
          onClick={(ev) => handleVersionSelected(ev, m.key)}
        >
          {DateTimeHelper.formatDateTime(m.createdAt)}
        </div>
      );
    });
  }, [historyContents, selectedVersion]);

  const selectedVersionHistory = useMemo(() => {
    return historyContents.find((m) => m.key === selectedVersion);
  }, [selectedVersion, historyContents]);

  const popUpContainerCss = useMemo(() => {
    const isEmpty = historyContents.length === 0;

    return CssClassNameBuilder.new()
      .add("history-popup-container")
      .addConditional(isEmpty, "empty")
      .build();
  }, [historyContents]);

  return (
    <PopupContainer className="history-popup">
      <div className="history-popup-header">
        <h3>Page history</h3>
        <div className="icon-close-popup" onClick={closePopup}>
          {" "}
          <CloseSVG />
        </div>
      </div>
      <LineSeparator />
      <div className={popUpContainerCss}>
        {historyContents.length === 0 ? (
          <EmptyDocumentIllustrationSVG />
        ) : (
          <>
            <div className="history-versions">{historyVersions}</div>
            <div className="history-main scroll-parent">
              <div className="history-main-content page-scroll">
                <FlexLayout direction="horizontal">
                  <div className="content-column">
                    {parsedSelectedVersionContent(
                      selectedVersionHistory?.newContent || ""
                    )}
                  </div>
                  <div className="content-column">
                    {parsedSelectedVersionContent(
                      selectedVersionHistory?.oldContent || ""
                    )}
                  </div>
                </FlexLayout>
              </div>
            </div>
          </>
        )}
      </div>
    </PopupContainer>
  );
}
