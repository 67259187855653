import "./SplashScreent.css";

import { SpaceBackgroundLayout } from "../../../lib/layouts/space-background/SpaceBackgroundLayout";

export function SplashScreen() {
  return (
    <SpaceBackgroundLayout>
      <h1 className="logo-text">Orbital Watch</h1>
    </SpaceBackgroundLayout>
  );
}
