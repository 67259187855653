import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { Space } from "../models/Space";
import { SpaceByIdResponseDTO } from "../models/api/SpaceByIdResponseDTO";
import { SpaceContentsResponseDTO } from "../models/api/SpaceContentsResponseDTO";
import { SpaceCreateRequestDTO } from "../models/api/SpaceCreateRequestDTO";
import { SpaceCreateResponseDTO } from "../models/api/SpaceCreateResponseDTO";
import { SpaceResponseDTO } from "../models/SpaceResponseDTO";
import { SpaceUpdateRequestDTO } from "../models/api/UpdateSpaceRequestDTO";
import { UrlUtility } from "../../../lib/utils/UrlUtility";

const Route = (path: string) => {
  return UrlUtility.join(
    ConfigurationProvider.getConfiguration().App.BackendUrl,
    path
  );
};

export class SpaceService {
  async getSpaces(): Promise<Space[]> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route("/api/Spaces"),
    })
      .then((res) => {
        console.log("Spaces", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async getById(spaceId: string): Promise<Space> {
    return HttpClient.sessionRequest<SpaceByIdResponseDTO>({
      method: "GET",
      url: Route(`/api/Spaces/${spaceId}`),
    })
      .then((res) => {
        var mappedSpace: Space = {
          description: res.data.description,
          title: res.data.title,
          spaceId: res.data.spaceId,
          externalId: res.data.externalId,
          projectId: res.data.projectId,
        };
        return mappedSpace;
      })
      .catch((err) => {
        throw err
      });
  }

  async deleteSpace(spaceId: string): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/Spaces/${spaceId}`),
    })
      .then((_) => { })
      .catch((error) => {
        throw error;
      });
  }

  updateSpace(
    title?: string,
    description?: string,
    projectId?: string,
    id?: string
  ): Promise<SpaceResponseDTO> {

    var requestDTO: SpaceUpdateRequestDTO = {
      description: description,
      projectId: projectId,
      title: title
    };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/Spaces/${id}`),
      data: requestDTO,
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async createSpace(
    requestDTO: SpaceCreateRequestDTO
  ): Promise<SpaceCreateResponseDTO> {
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route("/api/Spaces"),
      data: requestDTO,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async getSpaceContent(id: string): Promise<SpaceContentsResponseDTO> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/Spaces/${id}/contents`),
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
}
