import { ActionItem, ItemDefinition } from "./Tree";
import { ClosedFolderSVG, FileSVG, OpenFolderSVG } from "../../assets/icons";
import { useCallback, useEffect, useMemo, useState } from "react";

import { CssClassNameBuilder } from "../../utils/CssClassNameBuilder1";
import { IconButton } from "../buttons/IconButton";
import { MoreActions } from "./MoreActions";

interface TreeItemProps<T> {
    item: T;
    selectedItem?: T;
    onSelectedItemChanged: (item: T) => void;
    itemDefinition: ItemDefinition<T>;
    isItemActive: (item: T) => boolean;
}

export function TreeItem<T>(props: TreeItemProps<T>) {

    const [expanded, setExpanded] = useState(false);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    const children = useMemo(() => {
        return props.itemDefinition.children && props.itemDefinition.children(props.item)
    }, [props.itemDefinition.children])

    const otherActions = useMemo(() => {
        if (props.itemDefinition.actions === undefined) return [];
        const actions = props.itemDefinition.actions(props.item);
        if (actions.length <= 1) return [];

        return actions.slice(1);
    }, [props.itemDefinition.actions, props.item])

    const isSelected = useMemo(() => { return props.item === props.selectedItem; }, [props.item, props.selectedItem])

    function IsActiveDeepSearch(source: T): boolean{
        if(props.isItemActive(source)){
          return true;
        }

        const children = props.itemDefinition.children && props.itemDefinition.children(source);
        const results  = children?.map((c : any) => IsActiveDeepSearch(c));
        const hasChildActivated = results && results.filter(t => t).length > 0;

        return hasChildActivated || false;
    }

    useEffect(() => {
        if(props.isItemActive(props.item)) {
            props.onSelectedItemChanged(props.item);
        } else if (IsActiveDeepSearch(props.item)) {
            setExpanded(true);
        }
    }, [props.isItemActive]);


    /****************************
    * USER INTERACTION
    *****************************/

    const handleExpandClicked = useCallback((event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event?.stopPropagation();
        setExpanded(!expanded)
    }, [expanded, setExpanded])

    const handleItemClicked = useCallback((event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event?.stopPropagation();

        handleExpandClicked(event);

        props.onSelectedItemChanged(props.item)
        props.itemDefinition.onItemClicked && props.itemDefinition.onItemClicked(props.item)
    }, [props.itemDefinition.onItemClicked, props.item, handleExpandClicked])

    const handleMainActionClicked = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | undefined, action: ActionItem<T>) => {
        event?.stopPropagation();
        action.onClick(props.item)
    }, [props.item])



    /****************************
    * CSS && HTML
    *****************************/

    const treeItemContectCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("tree-item-content")
            .addConditional(isSelected, "highlighted")
            .build();
    }, [isSelected])

    const itemMainActionCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("item-main-action")
            .addConditional(isSelected, "highlighted")
            .build();
    }, [isSelected])

    const mainAction = useMemo(() => {
        const [action] = props.itemDefinition.actions && props.itemDefinition.actions(props.item) || [];

        if (action === undefined) {
            return <div className={itemMainActionCss}></div>
        }

        return (
            <IconButton icon={action.icon} className={itemMainActionCss} onClick={(e) => handleMainActionClicked(e, action)} />
        )
    }, [props.itemDefinition.actions, props.item, itemMainActionCss])




    return (
        <div className='tree-item'>
            <div className={treeItemContectCss} onClick={(e) => handleItemClicked(e)}>
                {children ?
                    <div className='tree-icon tree-expand' onClick={(e) => handleExpandClicked(e)}>{expanded ? <OpenFolderSVG /> : <ClosedFolderSVG />}</div>
                    :
                    <div className='tree-icon'><FileSVG /> </div>
                }
                {props.itemDefinition.icon &&
                    (<div className='tree-icon'>{props.itemDefinition.icon(props.item)}</div>)
                }
                <div className='tree-label'>{props.itemDefinition.render(props.item)}</div>
                <div className='tree-actions'>
                    {mainAction}
                    <MoreActions actions={otherActions} item={props.item} highlighted={isSelected} />
                </div>
            </div>
            {children && expanded ?
                <div className='tree-item-children'>
                    {children.map((child, index) => (<TreeItem
                        key={index}
                        item={child}
                        isItemActive={props.isItemActive}
                        itemDefinition={props.itemDefinition}
                        onSelectedItemChanged={props.onSelectedItemChanged}
                        selectedItem={props.selectedItem}
                    />))}
                </div>
                : null}
        </div>
    )

}
