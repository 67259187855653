import './EditPagePopup.css';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCloseEditor, useClosePopup, useEditor, usePopup } from '../../../../lib/infrastructure/ui/UIServices';

import { AstroMarkCompiler } from '../../utils/AstroMarkCompiler';
import { Button } from '../../../../lib/components/buttons/Button';
import { ErrorPopup } from '../../../../lib/components/popup/ErrorPopup';
import { FormFieldTextInput } from '../../../../lib/components/form/form-field/FormFieldTextInput';
import { LineSeparator } from '../../../../lib/components/separator/LineSeparator';
import MdEditor from 'react-markdown-editor-lite';
import { PageContentChangedEvent } from '../../../../common/events/PageContentChangedEvent';
import { PageService } from '../../services/PageService';
import { PopupContainer } from '../../../../lib/layouts/containers/popup-container/PopupContainer';
import { Spacer } from '../../../../lib/components/separator/Spacer';
import { SuccessPopup } from '../../../../lib/components/popup/SuccessPopup';
import { TextButton } from '../../../../lib/components/buttons/TextButton';
import { requiredValidator } from '../../../../lib/utils/ValidationChecks';
import { useFormControl } from '../../../../lib/components/form/Form';
import { useServiceCall } from '../../../../common/services/UseServiceCall';

export interface EditPageRequestFromPopupDTO {
    title: string | undefined;
    content: string | undefined;
    spaceId: string;
    pageId: string;
}

export interface IEditPagePopupProps {
    spaceId: string;
    pageId: string;
}



const pagesSvc = new PageService();

export function EditPagePopup(props: IEditPagePopupProps) {

    const closeEditor = useCloseEditor();
    const openPopup = usePopup();

    const editorRef = useRef<MdEditor>(null);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
    const [content, setContent] = useState<string>();

    const pageTitleFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true });

    /****************************
     * DATA REQUESTS
     *****************************/

    useEffect(() => {
        if (props.spaceId && props.pageId) {
            pagesSvc.getPageById(props.spaceId, props.pageId).then((result) => {
                editorRef.current?.setText(result?.pageContent || "");
                pageTitleFormControl.setValue(result.title);
                setIsLoadingData(false);
            }).catch((error) => {
                setIsLoadingData(false);
                openPopup(<ErrorPopup>{error.code}</ErrorPopup>);
            })
        }
    }, []);

    const editPage = useServiceCall(pagesSvc.editPage);

    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    const isButtonDisabled = useMemo(() => {
        return editPage.isLoading || isLoadingData;
    }, [editPage]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleEditorChange = useCallback(({ html, text }: { html: any; text: any }) => {
        setContent(text);
    }, [setContent, content]);


    const handleSavePageClicked = useCallback(() => {

        if (!pageTitleFormControl.validate() || !props.spaceId)
            return;

        var request: EditPageRequestFromPopupDTO = { //See a better name to this
            title: pageTitleFormControl.value,
            content: content,
            spaceId: props.spaceId,
            pageId: props.pageId
        };

        editPage.invoke(request).then((response) => {
            openPopup(<SuccessPopup onDismissClicked={closeEditor}>You have edited a Page successfully!</SuccessPopup>);
            PageContentChangedEvent.firePageChanged();


        }).catch((err) => {
            openPopup(<ErrorPopup onDismissClicked={closeEditor}>{err.code}</ErrorPopup>);
        });
    },
        [pageTitleFormControl, content, setContent, props.spaceId, props.pageId, openPopup]
    );


    /****************************
    * CSS & HTML
    *****************************/


    return (
        <PopupContainer className="edit-page-popup">
            <div className="edit-page-popup-header">
                <FormFieldTextInput placeholder="Insert a title" formControl={pageTitleFormControl} />
                <div className="act-btns">
                    <TextButton type="white" text={"Cancel"} onClick={closeEditor} />
                    <Button text="Save" type="primary" isDisabled={isButtonDisabled}
                        onClick={handleSavePageClicked} />
                </div>
            </div>
            <LineSeparator />


            <Spacer px="15" mode="vertical" />
            <div className="page-content-renderer mk-editor">
                <MdEditor ref={editorRef} renderHTML={(text:any) => AstroMarkCompiler.compileToHtml(text)} onChange={handleEditorChange} />
            </div>

        </PopupContainer>
    );

}
