import "./AreaContainer.css";

import { CssClassNameBuilder } from '../../utils/CssClassNameBuilder1';
import {useMemo} from 'react';

interface IAreaContainerProps {
    className?: string;
    grow?: 'horizontally' | 'vertically';
    children: React.ReactNode;
}

export function AreaContainer(props: IAreaContainerProps){

    const css = useMemo(() => {
        const mode = props.grow || 'vertically'
        return CssClassNameBuilder.new()
        .add("area-container")
        .add(props.className)
        .add(`grow-${mode}`)
        .build()
    }, [props.className, props.grow]);


        return (
            <div className={css}>
                {props.children}
            </div>
        )
}
