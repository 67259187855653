import "./DoubtsPage.css";

import React, { useCallback, useRef, useState } from "react";

import { Button } from "../../../../../lib/components/buttons/Button";
import { GPTService } from "../../../services/GPTService";
import { Loader } from "../../../../../lib/components/loader/Loader";
import { PageContainer } from "../../../../../lib/layouts/containers/page/PageContainer";
import { PageContentRenderer } from "../PageContentRenderer";
import { SpaceService } from "../../../../spaces/services/SpaceService";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { TextInput } from "../../../../../lib/components/form/text-input/TextInput";

let gptService = new GPTService();
let spaceService = new SpaceService();

export interface DoubtsProps {
  spaceId: string;
}

export function DoubtsPage(props: DoubtsProps) {

  const [answer, setAnswer] = React.useState('');
  const [question, setQuestion] = React.useState('');

  const markdownDiv = useRef<HTMLDivElement>(null);

  const handleChangeQuestion = useCallback((value: any) => {
    setQuestion(value);
  }, [setQuestion])

  const [loading, setLoading] = React.useState(false);

  /****************************
 * DATA REQUESTS
 *****************************/

  const submit = useCallback(async () => {
    setLoading(true);
    gptService.clarifyDoubt({
      space: props.spaceId,
      information: question
    }).then(res => {
      setAnswer(res.content);
      setLoading(false);
    });
  }, [question, props.spaceId]);

  const updateChat = useCallback(async () => {
    setLoading(true);
    await spaceService.getSpaceContent(props.spaceId).then(res => {
      setLoading(false);
    });
  }, [props.spaceId]);

  /****************************
 * DATA MANIPULATION EFFECTS
 *****************************/

  /****************************
* USER ACTIONS
*****************************/

  /****************************
 * CSS & HTML
 *****************************/
  return (
    <div className="page-area">
      <div className="page-content scroll-parent">
        <PageContainer className="doubts-page">
          <h1>Q&A</h1>
          <Spacer mode="vertical" px="22" />
          <TextInput
            className="doubts-add-textInput"
            value={question}
            onChange={handleChangeQuestion}
          />
          <Spacer mode="vertical" px="12" />
          <div className="doubts-buttons">
            <Button
              className="doubts-add-button"
              text="Submit"
              type="primary"
              onClick={submit}
              isDisabled={loading}
            />
            <Spacer mode="horizontal" px="12" />
            <Button
              className="doubts-update-chat"
              text="Update Chat"
              type="secondary"
              onClick={updateChat}
              isDisabled={loading}
            />
          </div>

          <Spacer mode="vertical" px="20" />
          <div className=".doubts-answer-body">
            <div className=".doubts-answer-wrapper">
              {loading ? <Loader /> : (
                <PageContentRenderer
                  markdownDivRef={markdownDiv}
                  hideToc={true}
                  markdownContent={answer}
                />
              )}
            </div>
          </div>
        </PageContainer>
      </div>
    </div>
  );
}