import { Navigate, Route, Routes } from "react-router-dom";

import { IdentityAction } from "../lib/infrastructure/identity/components/IdentityAction";
import { SessionExpiredPage } from "../common/components/session-expired/SessionExpiredPage";

export function AppIdentityRoutingModule(){

    return (
        <Routes>
            <Route path="login" element={<IdentityAction exec={(svc, calledFromUrl) => svc.loginBackToUrl(calledFromUrl ?? "/")} />}/>
            <Route path="logout" element={ <IdentityAction exec={(svc) => svc.logout()} />}/>
            <Route path="sessionexpired" element={<SessionExpiredPage/>}/>

            <Route path="forgottenPassword"  element={<IdentityAction exec={(svc) => svc.forgottenPassword()} />} />

            <Route path="*" element={<Navigate to="/notfound"/>}/>
        </Routes>

    )
}
