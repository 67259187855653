import { useCallback, useMemo, useState } from "react";

type ServiceCall<TRes, TIn = void> = (params: TIn) => Promise<TRes>;


export function useServiceCall<TRes, TIn = void>(serviceCall: ServiceCall<TRes, TIn>) {
  const [isLoading, setIsLoading] = useState(false);

  const invoke = useCallback(
    (params: TIn) => {
      setIsLoading(true);
      return serviceCall(params)
        .then((res) => {
          setIsLoading(false);
          return res;
        })
        .catch((err) => {
          setIsLoading(false);
          throw err;
        });
    },
    [setIsLoading, serviceCall]
  );

  return { isLoading, invoke };
}



export function useServiceCallPro<T extends (...args: Parameters<T>) => ReturnType<T>>(serviceCall: T) {
  const [isLoading, setIsLoading] = useState(false);

  const invoke = useCallback((...params: Parameters<T> ) => {
      setIsLoading(true);
      return Promise.resolve(serviceCall(...params))
        .then((res) => {
          setIsLoading(false);
          return res;
        })
        .catch((err) => {
          setIsLoading(false);
          throw err;
        });
    },
    [setIsLoading, serviceCall]
  );

  const values = useMemo(() => ({ isLoading, invoke }), [isLoading, invoke])

  return values;
}
