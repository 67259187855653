import "./NavBar.css";

import { Button } from "../buttons/Button";
import { ReactComponent as FolderSVG } from "../../assets/icons/folder-title.svg";
import { NavBarAppMenu } from "./nav-bar-app-menu/NavBarAppMenu";
import { ReactComponent as NavbarIconSVG } from "../../assets/icons/navbar-icon.svg";
import { Spacer } from "../separator/Spacer";
import { useIdentityUserDisplayName } from "../../infrastructure/identity/hooks/useIdentityUserDisplayName";
import { useNavigate } from "react-router-dom";
import { useSelectedSpace } from '../../infrastructure/ui/UIServices';
import { useState } from "react";

export function NavBar() {
  const navigate = useNavigate();

  const selectedProject = useSelectedSpace();
  const [showMenu, setShowMenu] = useState(false);
  const {userDisplayName, userDisplayNameIsLoading} = useIdentityUserDisplayName();

  return (
    <div className="header">

      <div className="header-menu-btn" onClick={() => {setShowMenu(!showMenu)}}> <NavbarIconSVG /></div>
      <div className="header-app-logo"  onClick={() => navigate('spaces')}>Ark</div>
      <div className="header-space"></div>
      <div className="header-project"> {selectedProject ? <><FolderSVG></FolderSVG> {selectedProject.name}</> : null}</div>
      <Spacer px={44} mode="horizontal" />
      <div className="header-user-actions">{userDisplayNameIsLoading ? null : userDisplayName}</div>
      
      {showMenu && <NavBarAppMenu onClose={() => setShowMenu(false)} />}
    </div>
  );
}
