import { AMTocNode } from "../../../../features/documentation/utils/astro-mark/AMTableOfContents";

export interface ITocNodeProps {
    node: AMTocNode;

  }
  export function TocNode(props:ITocNodeProps) {

    return (
      <li className="table-of-contents">
        <a href={props.node.anchor}>{props.node.label}</a>
        {props.node.children && props.node.children.length ?
          <ol>
            {props.node.children.map((i, idx) => <TocNode key={idx} node={i} />)}
          </ol>
        :null}
      </li>
    )
  }
