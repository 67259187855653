import "./ScrollLayout.css";

interface IScrollLayoutProps {
  children: React.ReactNode;
}


export function ScrollLayout(props: IScrollLayoutProps) {

  return (
    <div className="scroll-parent">
      <div className="page-scroll">
        {props.children}
      </div>
    </div>
  );
}
