import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { PageFileDTO } from "../models/services/PageFileDTO";
import { PageFilesResponse } from "../models/PageFilesResponse";
import { PageFilesResponseDTO } from "../models/services/PageFilesResponseDTO";
import { UrlUtility } from "../../../lib/utils/UrlUtility";
import { getAsByteArray } from "../../../lib/utils/FileHelper";

const Route = (path: string) => {
  return UrlUtility.join(
    ConfigurationProvider.getConfiguration().App.BackendUrl,
    path
  );
};

export class PageFilesService {
  async getFiles(spaceId: string, pageId: string): Promise<PageFilesResponse> {


    return HttpClient.sessionRequest<PageFilesResponseDTO>({
      method: "GET",
      url: Route(`/api/Spaces/${spaceId}/Pages/${pageId}/PageFiles`),
    })
      .then((res) => {

        var mappedFiles: PageFilesResponse = {
          files: res.data.files.map((file) => ({
            name: file.name,
            thumbnailUrl: file.thumbnailUrl,
            url: file.url,
          })),
          pageSize: res.data.pageSize,
        };

        return mappedFiles;
      })
      .catch((err) => {
        throw err;
      });
  }

  async uploadFiles(
    spaceId: string,
    pageId: string,
    files: File[]
  ): Promise<PageFileDTO[]> {

    return Promise.all(files.map(file =>
      getAsByteArray(file)
        .then(content => {
          let body = new FormData();

          body.append("file", new Blob([content], { type: file.type }), file.name);

          return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/Spaces/${spaceId}/Pages/${pageId}/PageFiles`),
            data: body,
          })
            .then((res) => {
              var uploadedFile: PageFileDTO = {
                name: res.data.name,
                url: res.data.url,
                thumbnailUrl: ""
              };

              return uploadedFile;
            })
            .catch((err) => {
              console.log(err);
              throw err;
            });
        })))


  }
}
