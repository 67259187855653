import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { CancelHttpEvent } from "../../events/CancelHttpEvent";

export interface APIError {
  model: number;
  message: string;
  details: string;
  stacktrace: string;
  httpCode: number;
  code: string;
  error: boolean;
}


function handleResponse(response: AxiosResponse<any, any>) {
  return response;
}



function handleError(error: any) {
  if(axios.isCancel(error) ) {return;}

  if(error.config.url.toLowerCase().includes("me/language")) {
    return;
  }


  const err = error as AxiosError;


  // if (error.code === "ERR_NETWORK") {
  //   CancelHttpEvent.cancelAllHttpRequests();
  //   window.location.href = "/unavailable";
  //   return;
  // }
  // if (err.response?.status === 401) {
  //   CancelHttpEvent.cancelAllHttpRequests();
  //   window.location.href = "/access-denied";
  // }

//   if(error.response.data && (error.response.data as any).error) {


//     throw error;
// } else {
//      CancelHttpEvent.cancelAllHttpRequests();
//      window.location.href = "/error";
//    }

  throw error;
}



export function AddAxiosInterceptors() {
  axios.interceptors.request.use(handleRequestSuccess, handleRequestError);
  axios.interceptors.response.use(handleResponse, handleError);
}


export const cancelationToken = axios.CancelToken.source();

function handleRequestSuccess(value: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>> {
  return {cancelToken: cancelationToken.token, ...value};
}

function handleRequestError(error: any) {
  debugger;
  throw error
}
