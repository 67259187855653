import "./PageHeader.css";

import { DateTimeHelper } from "../../../../lib/utils/DateTimeHelper";

interface IPageHeaderProps{
    title: string;
    author: string;
    date: string;
    children: React.ReactNode;
}


export function  PageHeader(props: IPageHeaderProps) {


    return (
        <div className="page-header">
            <div className="page-header-details">
                <div className="page-header-details-title">
                    <h1>{props.title}</h1>
                </div>
                <div className="page-header-details-subinfo">
                    <span>{props.author}</span> <span>{DateTimeHelper.formatDateTimeAsTimeAgo(props.date)}</span>
                </div>
            </div>
            <div className="page-header-actions">
                {props.children}
            </div>
        </div>
    )
}
