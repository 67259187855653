import Token from "markdown-it/lib/token";
import slugify from '@sindresorhus/slugify';

export interface AMTocNode {
  level: number;
  label: string | undefined;
  anchor: string | undefined;
  children: AMTocNode[];
}

export class AMTableOfContents {
  static headings2ast(tokens: Token[]) {
    const ast: AMTocNode = { level: 0, label: "", children: [], anchor: ""};
    const stack = [ast];

    if (tokens) {
      for (let i = 0, iK = tokens.length; i < iK; i++) {
        const token = tokens[i];
        if (token.type === "heading_open") {
          const key = tokens[i + 1].children
            ?.filter(function (token) {
              return token.type === "text" || token.type === "code_inline";
            })
            .reduce(function (s, t) {
              return s + t.content;
            }, "");

          const node: AMTocNode = {
            level: parseInt(token.tag.substring(1), 10),
            label: key,
            children: [],
            anchor: key ? "#"+slugify(key) : ""
          };

          if (node.level > stack[0].level) {
            stack[0].children.push(node);
            stack.unshift(node);
          } else if (node.level === stack[0].level) {
            stack[1].children.push(node);
            stack[0] = node;
          } else {
            while (node.level <= stack[0].level) stack.shift();
            stack[0].children.push(node);
            stack.unshift(node);
          }
        }
      }
    }
    return ast;
  }
}
