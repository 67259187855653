import { AMTableOfContents } from './astro-mark/AMTableOfContents';
import { AstroMarkFactory } from "./astro-mark/AstroMarkFactory";
import  markdownItAST  from 'markdown-it-ast';

var engine = AstroMarkFactory.CreateEngine();

export interface IASTNode {
    tag: string;
    type: string;
    hidden: boolean;
    content: string;
    children?: IASTNode[];
}


export class AstroMarkCompiler {

    static compileToHtml(content?:string) {
        return engine.render(content ?? "");
    }

    static tokens(content?: string){
        return  engine.parse(content ?? "" , {});
    }

    static generateAst(content: string) {
        let tokens = this.tokens(content)
        console.log(tokens);
        console.log(this.generateTocAst(content));
        let ast = markdownItAST.makeAST(tokens);
        return ast;
    }

    static generateTocAst(content?: string) {
        let tokens = this.tokens(content)
        return AMTableOfContents.headings2ast(tokens);
    }
}
