import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

export interface UISContextState {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  popupContent: React.ReactNode;
  setPopupContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;


  showEditor: boolean;
  setShowEditor: React.Dispatch<React.SetStateAction<boolean>>;
  editorContent: React.ReactNode;
  setEditorContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;

  viewPortWidth: number;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  viewPortHeight: number;
  setHeight: React.Dispatch<React.SetStateAction<number>>;

  selectedSpace: SelectedSpace|undefined;
  setSelectedSpace: React.Dispatch<React.SetStateAction<SelectedSpace|undefined>>

  notificationContent: React.ReactNode;
  setNotificationContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  showNotificationContainer: boolean;
  setShowNotificationContainer: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SelectedSpace {
  id: string;
  name: string;
}

export const UISContext = createContext<UISContextState | null>(null);

interface UISContextProps {
  children: React.ReactNode;
}

export function UISContextProvider({ children }: UISContextProps) {

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState<React.ReactNode>();

  const [showEditor, setShowEditor] = useState(false);
  const [editorContent, setEditorContent] = useState<React.ReactNode>();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);


  const [selectedSpace, setSelectedSpace] = useState<SelectedSpace>();

  const [notificationContent, setNotificationContent] = useState<React.ReactNode>();
  const [showNotificationContainer, setShowNotificationContainer] = useState(false);


  const value = {

    showPopup,
    setShowPopup,
    popupContent,
    setPopupContent,

    showEditor,
    setShowEditor,
    editorContent,
    setEditorContent,

    viewPortWidth: width,
    setWidth,
    viewPortHeight: height,
    setHeight,

    selectedSpace,
    setSelectedSpace,

    notificationContent,
    setNotificationContent,
    showNotificationContainer,
    setShowNotificationContainer

  };

  return <UISContext.Provider value={value}>{children}</UISContext.Provider>;
}

/**************************************
 *  Selected Space
 *************************************/
export function useSelectedSpace() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return context.selectedSpace;
}

export function useSetSelectedSpace()
  : [(SelectedSpace|undefined), React.Dispatch<React.SetStateAction<SelectedSpace|undefined>>] {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return [context.selectedSpace, context.setSelectedSpace];
}
/**************************************
 *  Popup
 *************************************/
export function useRenderPopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    showPopup: context.showPopup,
    popupContent: context.popupContent
  };
}

export function usePopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const openPopup = useCallback((content: React.ReactNode) => {
      context.setPopupContent(content);
      context.setShowPopup(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context.setShowPopup, context.setPopupContent]
  );

  return openPopup;
}

export function useClosePopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const closePopup = useCallback(() => {
    context.setShowPopup(false);
    context.setPopupContent(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.setShowPopup, context.setPopupContent]);

  return closePopup;
}

/**************************************
 *  Editor
 *************************************/
export function useRenderEditor() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    showEditor: context.showEditor,
    editorContent: context.editorContent
  };
}

export function useEditor() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const openEditor = useCallback((content: React.ReactNode) => {
      context.setEditorContent(content);
      context.setShowEditor(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context.setShowEditor, context.setEditorContent]
  );

  return openEditor;
}

export function useCloseEditor() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const closeEditor = useCallback(() => {
    context.setShowEditor(false);
    context.setEditorContent(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.setShowEditor, context.setEditorContent]);

  return closeEditor;
}

/**************************************
 *  Window Resize
 *************************************/
export function useWindowResize() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  useEffect(() => {
    const handleWindowResize = () => {
      context.setWidth(window.innerWidth);
      context.setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.setWidth, context.setHeight]);

  return context.viewPortWidth;
}


export function useNotification() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    notificationContent: context.notificationContent,
  };
}

export function useRenderNotificationContainer() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    showNotificationContainer: context.showNotificationContainer,
  };
}

export function useCreateNotification() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return useCallback((content: React.ReactNode) => {
      context.setNotificationContent(content);
      context.setShowNotificationContainer(true);
    },
    [context.setNotificationContent, context.setShowNotificationContainer]
  );
}
