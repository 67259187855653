export class PageContentChangedEvent {
    static firePageChanged() {
       let event = new CustomEvent<void>("page-content-changed");
       window.dispatchEvent(event);
    }

    static attachHandler(handler: EventListener){
       window.addEventListener("page-content-changed", handler);
    }

    static detachHandler(handler: EventListener){
       window.removeEventListener("page-content-changed", handler);
    }
   }
