import "./PopupArea.css";

import { useCallback, useMemo } from "react";

import { CssClassNameBuilder } from '../../../utils/CssClassNameBuilder1';
import { Overlay } from "../overlay/Overlay";
import { useClosePopup } from "../../../infrastructure/ui/UIServices";

interface IPopupProps {
  children: React.ReactNode;
  className?: string;
}

export function PopupArea(props: IPopupProps) {
  const closePopup = useClosePopup();

  const popupAreaCss = useMemo(() => {
    return CssClassNameBuilder.new()
      .add("popup-area")
      .addConditional(props.className, props.className)
      .build()
  }, [props.className]);

  const handleOnMouseDown = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.stopPropagation();
  }, []);

  const handleOverlayClick = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    closePopup();
  }, [closePopup]);

  return (
    <Overlay className="popup-overlay" onMouseDown={handleOverlayClick} >
      <div className={popupAreaCss}>
        <div className="popup-area-mouse-events" onMouseDown={handleOnMouseDown}>
          {props.children}
        </div>

      </div>
    </Overlay>
  );
}
