import "./PageContent.css";

import { CommentsSVG, FolderSVG } from "../../../../lib/assets/icons";
import {
  IRightSideBarButtons,
  RightSideBar,
} from "../../../../common/components/right-side-bar/RightSideBar";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import axios, { CancelTokenSource } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useCreateNotification,
  useEditor,
  usePopup,
  useSelectedSpace,
} from "../../../../lib/infrastructure/ui/UIServices";

import { AddState } from "../../../../lib/components/states/AddState";
import { AreaContainer } from "../../../../lib/layouts/containers/AreaContainer";
import { AstroMarkCompiler } from "../../utils/AstroMarkCompiler";
import { Button } from "../../../../lib/components/buttons/Button";
import { Comments } from "./comments/Comments";
import { DocumentSquareSVG } from "../../../../lib/assets/icons";
import { DropZonePopup } from "./dropzone/DropZonePopup";
import { DropzoneProvider } from "../../../../lib/layouts/dropzone/dropzone";
import { EditPagePopup } from "./EditPagePopup";
import { GetPageResponse } from "../../models/services/GetPageResponse";
import { Helmet } from "react-helmet";
import { Loader } from "../../../../lib/components/loader/Loader";
import { MediaPopup } from "./media/MediaPopup";
import { Notification } from './../../../../lib/components/notifications/Notification';
import { Page } from "../../models/Page";
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { PageContentChangedEvent } from "../../../../common/events/PageContentChangedEvent";
import { PageContentRenderer } from "./PageContentRenderer";
import { PageFilesService } from "../../services/PageFilesService";
import { PageHeader } from "./PageHeader";
import { PageHistoryPopup } from "./history/PageHistoryPopup";
import { PageService } from "../../services/PageService";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { Toc } from "../../../../lib/components/nav/toc/Toc";
import { useServiceCallPro } from "../../../../common/services/UseServiceCall";
import { CorrectionsPopup } from "./gpt/CorrectionsPopup";
import { ImprovementsPopup } from "./gpt/ImprovementsPopup";
import { AcceptanceTestsPopup } from "./gpt/AcceptanceTestsPopup";
import { TranslationPopup } from "./gpt/TranslationPopup";

function save(filename: any, data: any) {
  const blob = new Blob([data], { type: "text/csv" });
  if ((window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

interface IPageContentProps {
  defaultPage?: number;
}

var pageSvc = new PageService();
var pageFilesSvc = new PageFilesService();

const mapGetPageResponseToPage = (page: GetPageResponse): Page => ({
  pageId: page.pageId,
  title: page.title,
  pageContent: page.pageContent,
  dateCreated: page.dateCreated,
  dateModified: page.dateModified,
  modifiedBy: page.modifiedBy,
});



export function PageContent(props: IPageContentProps) {
  const cancelationToken = useRef<CancelTokenSource>();
  const targetPDFDiv = useRef<HTMLDivElement>(null);

  const markdownDiv = useRef<HTMLDivElement>(null);

  const isUploadingRef = useRef<any>();
  const openPopup = usePopup();
  const openEditor = useEditor();
  const createNotification = useCreateNotification();
  const navigate = useNavigate();
  const { spaceId, pageId } = useParams();

  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [isTocSectionOpen, setIsTocSectionOpen] = useState(false);
  const [pageInfo, setPageInfo] = useState<Page>();

  const getPageByIdCall = useServiceCallPro(pageSvc.getPageById);
  const uploadFilesCall = useServiceCallPro(pageFilesSvc.uploadFiles);

  const selectedSpace = useSelectedSpace();

  const currentSpace = useMemo(
    () => selectedSpace?.name ?? spaceId,
    [selectedSpace, spaceId]
  );

  /****************************
   * DATA REQUESTS
   *****************************/

  const getPage = useCallback(() => {
    if (!pageId || !spaceId) return;

    if (cancelationToken.current) {
      cancelationToken.current.cancel();
    }

    cancelationToken.current = axios.CancelToken.source();

    getPageByIdCall
      .invoke(spaceId, pageId, cancelationToken.current.token)
      .then((response) => {
        setPageInfo(mapGetPageResponseToPage(response));
      });
  }, [getPageByIdCall, pageId, spaceId, setPageInfo, cancelationToken]);

  const UploadFiles = useCallback((files: File[]) => {
    if (spaceId === undefined || pageId === undefined) return;

    uploadFilesCall
      .invoke(spaceId, pageId, files)
      .then((files) => {
        navigator.clipboard.writeText(`![${files[0].name}](${files[0].url})`);
        createNotification(
          <Notification
            type="success"
            title={"Success!"}
            text={'Success uploading file!'}
          ></Notification>
        );
      })
      .catch(_ => {
        createNotification(
          <Notification
            type="error"
            title={"Error!"}
            text={'Error uploading file!'}
          ></Notification>
        );
      });

  }, [uploadFilesCall.invoke, spaceId, pageId]);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  useEffect(() => {
    getPage();
  }, [pageId, spaceId]);

  useEffect(() => {
    const handlePageChanged = () => {
      getPage();
    };
    PageContentChangedEvent.attachHandler(handlePageChanged);
    return () => PageContentChangedEvent.detachHandler(handlePageChanged);
  }, [getPage]);

  useEffect(() => {
    if (!pageId && props.defaultPage) {
      navigate(props.defaultPage.toString(), { replace: true });
    }
  }, [pageId, props.defaultPage]);

  useEffect(() => {
    isUploadingRef.current?.setIsUploading(!!uploadFilesCall.isLoading);
  }, [uploadFilesCall.isLoading]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleSeeHistoryClicked = useCallback(() => {
    if (!pageId || !spaceId) return;
    openPopup(<PageHistoryPopup pageId={pageId} spaceId={spaceId} />);
  }, [pageId, spaceId]);

  const handleEditPageClicked = useCallback(() => {
    if (!pageId || !spaceId) return;
    openEditor(<EditPagePopup pageId={pageId} spaceId={spaceId} />);
  }, [pageId, spaceId]);

  const handleFileDrop = useCallback((files: File[]) => {
    UploadFiles(files);
  }, [UploadFiles])

  const handleMediaIconClicked = useCallback(() => {
    if (!pageId || !spaceId) return;
    openPopup(<MediaPopup ref={isUploadingRef} pageId={pageId} spaceId={spaceId} handleFileUpload={handleFileDrop} />);
  }, [pageId, spaceId, isUploadingRef, handleFileDrop]);

  const handleAcceptanceTestsClicked = useCallback(()=>{
    if (!pageId || !spaceId || !markdownDiv.current?.innerHTML) return;
    openPopup(<AcceptanceTestsPopup pageId={pageId} spaceId={spaceId} pageContent={markdownDiv.current?.innerHTML} />);
  }, [pageId, spaceId])

  const handleCorrectionsClicked = useCallback(()=>{
    if (!pageId || !spaceId || !markdownDiv.current?.innerHTML) return;
    openPopup(<CorrectionsPopup pageId={pageId} spaceId={spaceId} pageContent={markdownDiv.current?.innerHTML} />);
  }, [pageId, spaceId])

  const handleImprovementsClicked = useCallback(()=>{
    if (!pageId || !spaceId || !markdownDiv.current?.innerHTML) return;
    openPopup(<ImprovementsPopup pageId={pageId} spaceId={spaceId} pageContent={markdownDiv.current?.innerHTML} />);
  }, [pageId, spaceId])

  const handleTranslationClicked = useCallback(()=>{
    if (!pageId || !spaceId || !markdownDiv.current?.innerHTML) return;
    openPopup(<TranslationPopup pageId={pageId} spaceId={spaceId} pageContent={markdownDiv.current?.innerHTML} />);
  }, [pageId, spaceId])

  /****************************
   * CSS & HTML
   *****************************/
  const toc = useMemo(() => AstroMarkCompiler.generateTocAst(pageInfo?.pageContent).children, [pageInfo])


  const rightSideBarTopButtons: IRightSideBarButtons[] = useMemo(
    () => [
      {
        icon: <DocumentSquareSVG />,
        onClick: () => setIsTocSectionOpen(!isTocSectionOpen),
        isActive: isTocSectionOpen,
      },
      {
        icon: <CommentsSVG />,
        onClick: () => setIsCommentSectionOpen(!isCommentSectionOpen),
        isActive: isCommentSectionOpen,
      },
      {
        icon: <FolderSVG />,
        onClick: handleMediaIconClicked,
        isActive: false,
      },
    ],
    [isCommentSectionOpen, isTocSectionOpen, handleMediaIconClicked]
  );

  if (getPageByIdCall.isLoading) {
    return (
      <PageContainer>
        <AreaContainer>
          <Loader />
        </AreaContainer>
      </PageContainer>
    );
  }

  if (!pageId || !pageInfo || !spaceId) {
    return (
      <div className="page-content">
        <AddState text="Looks like this is empty! Try add a new page to this space!" />
      </div>
    );
  }

  return (
    <div className="page-area">
      <DropzoneProvider
        onEnter={() => openPopup(<DropZonePopup ref={isUploadingRef} pageId={pageId || ""} spaceId={spaceId || ""} />)}
        onDrop={handleFileDrop}
      />
      <Helmet>
        <title>
          {pageInfo.title} | {currentSpace} | Ark
        </title>
        <meta
          name="description"
          content={`Space: ${currentSpace}, Page: ${pageInfo.title}, Last Modified: ${pageInfo.dateModified}`}
        />
      </Helmet>

      <div className="page-content scroll-parent">
        <PageContainer className="page-content-container panel-1">
          <div className="page-content-body">
            <div className="page-content-wrapper">
              <PageHeader
                title={pageInfo.title}
                author={pageInfo.modifiedBy}
                date={pageInfo.dateModified}
              >
                <>
                  {/* <Button
                    className="addspace-button"
                    text="AstroMark AST"
                    type="secondary"
                    onClick={() => {
                      AstroMarkCompiler.generateAst(
                        pageInfo.pageContent || ""
                      );
                    }}
                  />
                  <Button
                    className="addspace-button"
                    text="AstroMark Tokens"
                    type="secondary"
                    onClick={() => {
                      AstroMarkCompiler.tokens(
                        pageInfo.pageContent || ""
                      );
                    }}
                  /> */}

                  <Button
                    className="addspace-button"
                    text="See history"
                    type="secondary"
                    onClick={handleSeeHistoryClicked}
                  />
                  <Button
                    className="addspace-button"
                    text="Edit Page"
                    type="primary"
                    onClick={handleEditPageClicked}
                  />
                  <Button
                    className="addspace-button"
                    text="Acceptance Tests"
                    type="white"
                    onClick={handleAcceptanceTestsClicked}
                  />
                  <Button
                    className="addspace-button"
                    text="Corrections"
                    type="white"
                    onClick={handleCorrectionsClicked}
                  />
                  <Button
                    className="addspace-button"
                    text="Improvements"
                    type="white"
                    onClick={handleImprovementsClicked}
                  />
                  <Button
                    className="addspace-button"
                    text="Translation"
                    type="white"
                    onClick={handleTranslationClicked}
                  />                  
                </>
              </PageHeader>
              <Spacer mode="vertical" px="30" />
              <PageContentRenderer
                markdownDivRef={markdownDiv}
                hideToc={isTocSectionOpen}
                markdownContent={pageInfo.pageContent}
                emptyPageOnClick={handleEditPageClicked}
                componentRef={targetPDFDiv}
              />
            </div>
          </div>
        </PageContainer>
      </div>
      {isTocSectionOpen && (
        <div className="scroll-parent panel-2 ">
          <PageContainer className="page-content-comments-container">
            <h3> On this page </h3>
            <Spacer px={18} mode="vertical" />
            <Toc items={toc}/>
          </PageContainer>
        </div>
      )}

      {isCommentSectionOpen && (
        <div className="scroll-parent panel-2 ">
          <PageContainer className="page-content-comments-container">
            <Comments />
          </PageContainer>
        </div>
      )}



      <div className="panel-3">
        <RightSideBar buttons={rightSideBarTopButtons} />
      </div>
    </div>
  );
}
