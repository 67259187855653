
import { DateTime } from "luxon";

export class DateTimeHelper {

  static formatDateTime(dateString: string) {
    let date = DateTime.fromISO(dateString).toLocal();

    return date.toFormat("yyyy/MMM/dd HH:mm:ss");
  }

  static formatDateTimeAsTimeAgo(dateString: string) {

   return  DateTime.fromISO(dateString).toLocal().toRelative();

  }
}
