import "./Comments.css";

import { ItemDefinition, Tree } from "../../../../../lib/components/tree/Tree";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Button } from "../../../../../lib/components/buttons/Button";
import { Comment } from "../../../models/Comment"
import { Comment as CommentComponent } from "./Comment";
import { CommentDetailDTO } from "../../../models/services/GetPageComments";
import { CommentService } from "../../../services/CommentService";
import { CreateCommentRequest } from "../../../models/services/CreateCommentRequest";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { SubComment } from "./SubComment";
import { UpdateCommentRequest } from "../../../models/services/UpdateCommentRequest";
import { requiredValidator } from "../../../../../lib/utils/ValidationChecks";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useOnInit } from "../../../../../lib/infrastructure/ui/useOnInit";
import { useParams } from "react-router-dom";
import { usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../../common/services/UseServiceCall";

var commentSvc = new CommentService();

interface ReplayComment {
  parent: Comment,
  child?: Comment,
  publishMode: 'new' | 'edit'
}

const mapCommentDetailDtoToComment = (commentDetail: CommentDetailDTO): Comment => ({
  commentId: commentDetail.commentId,
  content: commentDetail.content,
  children: commentDetail.comments?.map(mapCommentDetailDtoToComment) || [],
  profileName: commentDetail.authorName,
  timeStamp: DateTime.fromISO(commentDetail.timeStamp),
  isAuthor: commentDetail.isAuthor,
  isDeleted: commentDetail.isDeleted,
  isEdited: commentDetail.isEdited
})

export function Comments() {
  const { spaceId, pageId } = useParams();
  const openPopup = usePopup();

  const [comments, setComments] = useState<Comment[]>([]);
  const [publishCommentInfo, setPublishCommentInfo] = useState<ReplayComment>();

  const getPageCommentsCall = useServiceCallPro(commentSvc.getPageComments);
  const createCommentCall = useServiceCallPro(commentSvc.createComment);
  const editCommentCall = useServiceCallPro(commentSvc.editComment);
  const deleteCommentCall = useServiceCallPro(commentSvc.deleteComment);

  const commentTextFormControl = useFormControl<string>({
    validators: [requiredValidator()]
  });

  const replyCommentTextFormControl = useFormControl<string>({
    validators: [requiredValidator()]
  });

  /****************************
  * DATA REQUESTS
  *****************************/

  const getComments = useCallback(() => {
    if (!spaceId || !pageId) return;

    getPageCommentsCall.invoke(spaceId, pageId)
      .then((response) => {
        setComments(response.comments.map(mapCommentDetailDtoToComment));
      });
  }, [setComments, spaceId, pageId, getPageCommentsCall.invoke]);

  const createComment = useCallback((comment: string) => {
    if (!spaceId || !pageId) return;

    const request: CreateCommentRequest = {
      content: comment,
      parentCommentId: publishCommentInfo?.parent.commentId
    }

    createCommentCall.invoke(request, spaceId, pageId)
      .then(() => {
        getComments()
        setPublishCommentInfo(undefined);
      })
      .catch((error) => {
        if (error.response.data) {
          openPopup(<ErrorPopup>{error.response.data}</ErrorPopup>);
        } else {
          openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        }
      });

  }, [spaceId, pageId, publishCommentInfo?.parent.commentId, createCommentCall, getComments, openPopup]);

  const editComment = useCallback((comment: string) => {

    const updatingCommentId = publishCommentInfo?.child?.commentId || publishCommentInfo?.parent.commentId || "";

    if (!spaceId || !pageId || !updatingCommentId) return;

    const request: UpdateCommentRequest = {
      content: comment,
    }

    editCommentCall.invoke(request, spaceId, pageId, updatingCommentId)
      .then(() => {
        getComments()
        setPublishCommentInfo(undefined);
      })
      .catch((error) => {
        if (error.response.data) {
          openPopup(<ErrorPopup>{error.response.data}</ErrorPopup>);
        } else {
          openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        }
      });

  }, [getComments, spaceId, pageId, publishCommentInfo, openPopup, editCommentCall.invoke]);

  const deleteComment = useCallback((comment: Comment) => {

    const deleteCommentId = comment.commentId || "";

    if (!spaceId || !pageId || !deleteCommentId) return;

    deleteCommentCall.invoke(spaceId, pageId, deleteCommentId)
      .then(() => {
        getComments()
        setPublishCommentInfo(undefined);
      })
      .catch((error) => {
        if (error.response.data) {
          openPopup(<ErrorPopup>{error.response.data}</ErrorPopup>);
        } else {
          openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        }
      });

  }, [getComments, spaceId, pageId, openPopup, deleteCommentCall.invoke]);


  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  useEffect(() => {
    getComments();
  }, [spaceId, pageId]);

  useEffect(() => {
    replyCommentTextFormControl.setIsDisabled(createCommentCall.isLoading);
    commentTextFormControl.setIsDisabled(createCommentCall.isLoading);
  }, [createCommentCall.isLoading]);

  useEffect(() => {
    if (publishCommentInfo === undefined) {
      replyCommentTextFormControl.setValue("");
      commentTextFormControl.setValue("");
    }

    if (publishCommentInfo?.publishMode === "edit") {
      replyCommentTextFormControl.setValue(publishCommentInfo.child?.content || publishCommentInfo.parent.content);
    }
  }, [publishCommentInfo]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleReplyOrEditPublishButtonClicked = useCallback(() => {
    // replyCommentTextFormControl.validate();

    if (!spaceId || !pageId || !replyCommentTextFormControl.validate()) return;

    switch (publishCommentInfo?.publishMode) {
      case 'new':
        createComment(replyCommentTextFormControl.value || "")
        break;
      case 'edit':
        editComment(replyCommentTextFormControl.value || "")
        break;
    }
  }, [createComment, editComment, replyCommentTextFormControl, spaceId, pageId, publishCommentInfo]);

  const handleNewCommentButtonClicked = useCallback(() => {
    if (!spaceId || !pageId || !commentTextFormControl.validate()) return;

    createComment(commentTextFormControl.value || "")
  }, [createComment, commentTextFormControl, spaceId, pageId]);

  const handleDeleteCommentClicked = useCallback((comment: Comment) => {
    if (!spaceId || !pageId || !comment?.commentId) return;

    deleteComment(comment)
  }, [createComment, commentTextFormControl, spaceId, pageId]);


  /****************************
   * CSS & HTML
   *****************************/

  const ReplyCommentInput = useMemo(() => (<>
    <FormFieldTextInput
      placeholder="Write a comment"
      formControl={replyCommentTextFormControl}
    />
    <Spacer px={14} mode="vertical" />
    <Button
      className="addcomment-button"
      text={publishCommentInfo?.publishMode === 'edit' ? 'Edit' : 'Reply'}
      type="tertiary"
      onClick={handleReplyOrEditPublishButtonClicked}
      isDisabled={replyCommentTextFormControl.isDisabled}
    />
    <Spacer px={20} mode="vertical" />
  </>), [replyCommentTextFormControl, handleReplyOrEditPublishButtonClicked])

  const renderComments = useMemo(() => comments.flatMap(comment => {
      var mainComments = [
        <CommentComponent
          item={comment}
          onReplyClicked={() => setPublishCommentInfo({ parent: comment, publishMode: 'new' })}
          onEditClicked={() => setPublishCommentInfo({ parent: comment, publishMode: 'edit' })}
          onDeleteClicked={handleDeleteCommentClicked}
        />
      ];

      if (publishCommentInfo && !publishCommentInfo.child && publishCommentInfo.parent.commentId === comment.commentId) {
        mainComments.push(ReplyCommentInput)
      }

      var subs = comment.children.flatMap(subComment => {
        var sub = [
          <SubComment
            item={subComment}
            onReplyClicked={() => setPublishCommentInfo({ parent: comment, child: subComment, publishMode: 'new' })}
            onEditClicked={() => setPublishCommentInfo({ parent: comment, child: subComment, publishMode: 'edit' })}
            onDeleteClicked={handleDeleteCommentClicked}
          />];

        if (publishCommentInfo && publishCommentInfo.child?.commentId === subComment.commentId) {
          sub.push(ReplyCommentInput)
        }
        return sub;
      });
      return [mainComments, subs]
  }), [comments, publishCommentInfo, setPublishCommentInfo, ReplyCommentInput, handleDeleteCommentClicked])


  return (
    <div className="comment-section">
      <h3> Comments </h3>

      <Spacer px={18} mode="vertical" />

      <FormFieldTextInput
        placeholder="Write a comment"
        formControl={commentTextFormControl}
      />

      <Spacer px={14} mode="vertical" />

      <Button className="addcomment-button" text="Publish" type="tertiary" onClick={handleNewCommentButtonClicked} />

      <Spacer px={20} mode="vertical" />

      {renderComments}


    </div>
  );
}
