import "./Notification.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { CssClassNameBuilder } from "../../utils/CssClassNameBuilder1";
import { ReactComponent as NotificationCloseIcon } from "../../assets/icons/notification-close-icon.svg";
import { ReactComponent as NotificationErrorIcon } from "../../assets/icons/notification-error-icon.svg";
import { ReactComponent as NotificationSuccessIcon } from "../../assets/icons/notification-success-icon.svg";

// import { useCloseNotification } from "../../infrastructure/ui/UIServices";

interface INotificationProps {
  type: string;
  icon?: React.ReactNode;
  title: string;
  text: string;
}

export function Notification(props: INotificationProps) {
  // const closeNotification = useCloseNotification();

  const [onRenderClass, setOnRenderClass] = useState<boolean>(false);

  useEffect(() => {
    setOnRenderClass(true);
    setTimeout(() => {
      setOnRenderClass(false);
      // setTimeout(() => closeNotification(), 400);
    }, 3000);
  }, [setOnRenderClass]);

  const _notificationIcon = useCallback(() => {
    switch (props.type) {
      case "success":
        return <NotificationSuccessIcon />;
      case "error":
        return <NotificationErrorIcon />;
      case "other":
        return props.icon;
    }
  }, [props.type, props.icon]);

  const pageContentRendererCss = useMemo(() => {
    return CssClassNameBuilder.new()
      .add("notification")
      .add(props.type)
      .add(onRenderClass ? "show" : "hide")
      .build();
  }, [props.type, onRenderClass]);

  return (
    <div className={pageContentRendererCss}>
      <div className={"notification-icon " + props.type}>{_notificationIcon()}</div>
      <div className="notification-info">
        <div className="notification-info-title">{props.title}</div>
        <div className="notification-info-text">{props.text}</div>
      </div>
      <div
        className="notification-close"
        onClick={() => {
          setOnRenderClass(false);
          // setTimeout(() => closeNotification(), 400);
        }}
      >
        <NotificationCloseIcon className="notification-close-icon" />
      </div>
    </div>
  );
}
