import { AddAxiosInterceptors } from "../http/AddAxiosInterceptor";
import { ConfigurationProvider } from "../configuration/ConfigurationProvider";
import { IdentityProvider } from "../identity/IdentityProvider";

export class Startup {
  static async Run(app: () => void) {

     AddAxiosInterceptors();


    await ConfigurationProvider.init();

    await IdentityProvider.init();

    app();
  }
}
