import "./Sidebar.css";

import { IconButtonNavLink } from "../buttons/IconButtonNavLink";
import { MenuItem } from "./MenuItem";
import { SettingsSVG } from "../../assets/icons";
import { SideBarButton } from "./SideBarButton";
import { useMemo } from "react";

export interface ISideBarProps {
    className?: string;
    buttonsTop: MenuItem[];
    buttonsBottom: MenuItem[];
}



export function Sidebar(props: ISideBarProps) {

    const sideBarTopButtons = useMemo(() => {
        return props.buttonsTop.map((m, idx) => {
            return (
                <IconButtonNavLink
                    key={idx}
                    icon={m.icon}
                    navigateTo={m.navigateTo}
                    className="buttons-height"
                />
            );
        })
    }, [props.buttonsTop]);

    const sideBarBottomButtons = useMemo(() => {
        return props.buttonsBottom.map((m, idx) => {
            return (
                <IconButtonNavLink
                    key={idx}
                    icon={m.icon}
                    navigateTo={m.navigateTo}
                    className="buttons-height"
                />
            );
        })
    }, [props.buttonsBottom]);

    return (
        <div className="side-bar">
            <div className="side-bar-main">
                {sideBarTopButtons}
            </div>
            <div className="side-bar-settings">
                {sideBarBottomButtons}
            </div>
        </div>
    );
}

