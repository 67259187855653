import "./ImprovementsPopup.css";

import { useEffect, useMemo } from "react";
import { useClosePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { CloseSVG } from "../../../../../lib/assets/icons";
import { LineSeparator } from "../../../../../lib/components/separator/LineSeparator";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { IconButton } from "../../../../../lib/components/buttons/IconButton";
import { CssClassNameBuilder } from "../../../../../lib/utils/CssClassNameBuilder1";
import React from "react";
import { Loader } from "../../../../../lib/components/loader/Loader";
import { GPTService } from "../../../services/GPTService";

export interface IImprovementsProps {
  pageId: string;
  spaceId: string;
  pageContent: string;
}

var service = new GPTService();

export function ImprovementsPopup(props: IImprovementsProps) {

  const closePopup = useClosePopup();

  const [answer, setAnswer] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    service.suggestImprovements(props.pageContent).then(res => {
      setAnswer(res.content);
      setLoading(false)
    })
  }, []);

  const popUpContainerCss = useMemo(() => {
    return CssClassNameBuilder.new()
      .add("i-popup-container")
      .build();
  }, []);

  return (
    <PopupContainer className="i-popup">
      <div className="i-popup-header">
        <h3>Improvements</h3>
        <IconButton icon={<CloseSVG />} onClick={closePopup} />
        </div>
        <LineSeparator />

        <div className={popUpContainerCss}>
          {loading ? <Loader /> : 
            (<div className="i-answer"> 
              <p> {answer} </p>
            </div>)}
        </div>
    </PopupContainer>
  );
}