import './EditSpacePage.css';

import { requiredValidator, validateGuid } from '../../../../lib/utils/ValidationChecks';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useServiceCall, useServiceCallPro } from '../../../../common/services/UseServiceCall';

import { Button } from '../../../../lib/components/buttons/Button';
import { ErrorPopup } from '../../../../lib/components/popup/ErrorPopup';
import { FormContainer } from '../../../../lib/layouts/containers/form/FormContainer';
import { FormFieldTextInput } from '../../../../lib/components/form/form-field/FormFieldTextInput';
import { Loader } from '../../../../lib/components/loader/Loader';
import { PageContainer } from '../../../../lib/layouts/containers/page/PageContainer';
import { SaveSVG } from '../../../../lib/assets/icons';
import { Space } from '../../models/Space';
import { SpaceService } from '../../services/SpaceService';
import { SuccessPopup } from '../../../../lib/components/popup/SuccessPopup';
import { WarningPopup } from '../../../../lib/components/popup/WarningPopup';
import { useFormControl } from '../../../../lib/components/form/Form';
import { usePopup } from '../../../../lib/infrastructure/ui/UIServices';

var spaceSvc = new SpaceService();

export function EditSpacePage() {

    const openPopup = usePopup();
    const navigate = useNavigate();

    const { spaceId: id } = useParams();



    const spaceProjectIdFormControl = useFormControl<string>({ validators: [validateGuid()], enableAutoValidate: true });
    const spaceTitleFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true });
    const spaceDescriptionFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true });

    /****************************
     * DATA REQUESTS
     *****************************/

    const getSpaceById = useServiceCall(spaceSvc.getById);
    const updateSpaceById = useServiceCallPro(spaceSvc.updateSpace);
    const deleteSpaceById = useServiceCall(spaceSvc.deleteSpace);

    const getSpace = useCallback(() => {
        if (!id) return;
        getSpaceById.invoke(id).then((response: Space) => {
            spaceProjectIdFormControl.setValue(response.projectId);
            spaceTitleFormControl.setValue(response.title);
            spaceDescriptionFormControl.setValue(response.description);
        }).catch((error) => {
            openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        });
    }, [id, spaceProjectIdFormControl, spaceTitleFormControl, spaceDescriptionFormControl, getSpaceById.invoke]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getSpace();
    }, [id]);

    const isButtonDisabled = useMemo(() => {
        return getSpaceById.isLoading || updateSpaceById.isLoading;
    }, [getSpaceById.isLoading, updateSpaceById.isLoading]);

    /****************************
     * USER ACTIONS
     *****************************/


    const handleDeleteOnConfirmClicked = useCallback(() => {
        if (!id) return;
        deleteSpaceById.invoke(id)
            .then(() => {
                openPopup(
                    <SuccessPopup>Space deleted successfully!</SuccessPopup>
                );
                navigate("/spaces");
            })
            .catch((error) => {
                if (error.response.data) {
                    openPopup(<ErrorPopup>{error.response.data}</ErrorPopup>);
                } else {
                    openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
                }
            });
    }, [id, openPopup, deleteSpaceById.invoke]);


    const handleOnClickDeleteSpace = useCallback(() => {
        if (deleteSpaceById.isLoading) return;
        openPopup(
            <WarningPopup onConfirmClicked={handleDeleteOnConfirmClicked}>
                Are you sure you want to delete this space?
            </WarningPopup>
        )
    }, [handleDeleteOnConfirmClicked, openPopup, deleteSpaceById.isLoading]);



    const handleOnClickUpdateSpace = useCallback(() => {

        if (!spaceTitleFormControl.validate() || !spaceDescriptionFormControl.validate() || !spaceProjectIdFormControl.validate() || !id)
            return;

        updateSpaceById
            .invoke(spaceTitleFormControl.value, spaceDescriptionFormControl.value, spaceProjectIdFormControl.value, id).
            then(() => {
                openPopup(<SuccessPopup>
                    <div>Space edited with success!</div>
                </SuccessPopup>);
                getSpace();
            }).catch((error) => {
                openPopup(<ErrorPopup><div>{error.message}</div></ErrorPopup>);
            });

    }, [spaceProjectIdFormControl, spaceDescriptionFormControl, spaceProjectIdFormControl, id, openPopup, updateSpaceById.invoke]);




    /****************************
     * CSS & HTML
     *****************************/

    if (getSpaceById.isLoading) return (
        <PageContainer className="edit-space-page">
            <Loader />
        </PageContainer>)

    return (
        <PageContainer className="edit-space-page">

            <h1>Edit overview</h1>

            <FormContainer className="edit-input-area">
                <FormFieldTextInput label='ID' formControl={spaceProjectIdFormControl}></FormFieldTextInput>
                <FormFieldTextInput label='Title' formControl={spaceTitleFormControl}></FormFieldTextInput>
                <FormFieldTextInput label='Description' formControl={spaceDescriptionFormControl}></FormFieldTextInput>
            </FormContainer>

            <div className="act-btns">
                <div className="action-delete" onClick={handleOnClickDeleteSpace}>Delete Space</div>
                <Button
                    className='action-save'
                    text="Save Changes"
                    type="primary"
                    icon={<SaveSVG />}
                    onClick={handleOnClickUpdateSpace}
                    isDisabled={isButtonDisabled}
                />
            </div>
        </PageContainer>
    )
}
