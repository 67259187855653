import { AcceptanceTestsResponseDTO } from "../models/gpt/AcceptanceTestsResponseDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CorrectionsResponseDTO } from "../models/gpt/CorrectionsResponseDTO";
import { DoubtsResponseDTO } from "../models/gpt/DoubtsResponseDTO";
import { ImprovementsResponseDTO } from "../models/gpt/ImprovementsResponseDTO";
import { TranslationResponseDTO } from "../models/gpt/TranslationResponseDTO";
import { UrlUtility } from "../../../lib/utils/UrlUtility";
import axios from "axios";

export interface DoubtsQuestionDto{
    space: string;
    information: string;
}

const Route = (path: string) => {
    return UrlUtility.join(
      ConfigurationProvider.getConfiguration().App.OrionUrl,
      path
    );
  };
  
export class GPTService{

    async suggestAcceptanceTests(pageHtmlContent: string) : Promise<AcceptanceTestsResponseDTO>{

        var res = await axios.request<AcceptanceTestsResponseDTO>({method : "POST", url: Route(`/AcceptanceTests`), data : { information: pageHtmlContent }});
        console.log(res.data);
        
        return res.data;
    }

    async suggestCorrections(pageHtmlContent: string) : Promise<CorrectionsResponseDTO>{

        var res = await axios.request<CorrectionsResponseDTO>({method : "POST", url: Route(`/Corrections`), data : { information: pageHtmlContent }});

        return res.data;
    }

    async suggestImprovements(pageHtmlContent: string) : Promise<ImprovementsResponseDTO>{

        var res = await axios.request<ImprovementsResponseDTO>({method : "POST", url: Route(`/Improvements`), data : { information: pageHtmlContent }});

        return res.data;
    }

    async clarifyDoubt(question: DoubtsQuestionDto): Promise<DoubtsResponseDTO>{

        var res = await axios.request<DoubtsResponseDTO>({method : "POST", url: Route(`/Doubts`), data : { space: question.space, information: question.information}});

        return res.data;
    }

    async translate(pageHtmlContent: string) : Promise<TranslationResponseDTO>{

        var res = await axios.request<TranslationResponseDTO>({method : "POST", url: Route(`/Translation`), data : { information: pageHtmlContent }});

        return res.data;
    }
}