import './Comments.css';

import { useCallback, useMemo, useState } from "react";

import { Comment as CommentModel } from '../../../models/Comment';
import { FlexLayout } from "../../../../../lib/layouts/containers/flex/FlexLayout";

interface ICommentProps {
    item: CommentModel;
    onReplyClicked?: (comment: CommentModel) => void;
    onEditClicked?: (comment: CommentModel) => void;
    onDeleteClicked?: (comment: CommentModel) => void;
}

export function Comment(props: ICommentProps) {


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    const acronym = useMemo(() => {
        const names = props.item.profileName.split(" ")
        const firstLetterFirstName = names[0].charAt(0)
        const firstLetterLastName = names.length > 1 ? names[names.length - 1].charAt(0) : ""

        return firstLetterFirstName + firstLetterLastName
    }, [props.item.profileName])

    /****************************
    * USER INTERACTION
    *****************************/

    const handleOnReplyClicked = useCallback(() => {
        props.onReplyClicked && props.onReplyClicked(props.item);
    }, [props.onReplyClicked, props.item])

    const handleOnEditClicked = useCallback(() => {
        console.log(props.item);
        props.onEditClicked && props.onEditClicked(props.item);
    }, [props.onEditClicked , props.item])


    const handleOnDeleteClicked = useCallback(() => {

    }, [])



    /****************************
    * CSS && HTML
    *****************************/



    return (
        <FlexLayout direction="horizontal" className="comment">
            <div className="comment-profile-icon">
                {acronym}
            </div>
            <FlexLayout direction="vertical"  >
                <FlexLayout direction="horizontal" className='comment-header' verticalAlign='center'>
                    <div className="profile-name">{props.item.profileName}</div>
                    {/* <div className="comment-date">29 May 2023 15h42</div> */}
                    <div className="comment-date">{props.item.timeStamp?.toFormat("dd LLL yyyy HH'h'mm")}</div>
                </FlexLayout>
                <div className="comment-description">{props.item.content}</div>
                <FlexLayout direction="horizontal" className='comment-actions' verticalAlign='center'>
                    <div onClick={handleOnReplyClicked} className='comment-action-button'> reply </div>

                    {props.item.isAuthor && !props.item.isDeleted && (<>
                        <div className='dot' />
                        <div onClick={handleOnEditClicked} className='comment-action-button'> edit </div>
                        <div className='dot' />
                        <div onClick={handleOnDeleteClicked} className='comment-action-button'> delete </div>
                    </>)}

                </FlexLayout>

            </FlexLayout>
        </FlexLayout>

    )

}
