import "./App.css";

import { Navigate, Route, Routes } from "react-router-dom";

import { AppIdentityRoutingModule } from "./modules/App.Identity.RoutingModule";
import { AppPortal } from "./lib/layouts/portal/AppPortal";
import { AppRoutingModule } from "./modules/App.RoutingModule";
import { EmbededDocumentationPage } from "./features/documentation/pages/documentation-home-page/EmbededDocumentationPage";
import { RequireAuth } from "./lib/infrastructure/identity/components/RequireAuth";
import { SplashScreen } from './common/components/splash-screen/SplashScreen';
import { useIdentityIsProcessing } from './lib/infrastructure/identity/hooks/useIdentityIsProcessing';

function App() {

  const  isProcessing  = useIdentityIsProcessing();



  if (isProcessing) return <SplashScreen />;

  if (window.location.hash !== '' && window.parent !== window){
    console.log("hash found" + window.location.hash);
    return <>login success</>;
  }

  return (
    <AppPortal>
      <Routes>
        <Route path="/" element={<Navigate to="/spaces" />} />
        <Route path="/notfound" element={<>Not found</>} />
        <Route path="/splashshet" element={<SplashScreen/>} />
        <Route path="/identity/*" element={<AppIdentityRoutingModule />} />
        <Route path="/embeded/spaces/:spaceId/pages/:pageId" element={<RequireAuth><EmbededDocumentationPage /></RequireAuth>} />
        <Route path="*" element={<RequireAuth><AppRoutingModule /></RequireAuth>} />
      </Routes>
    </AppPortal>
  );
}



export default App;
