import "./AddSpacePopup.css";

import { requiredValidator, validateGuid } from "../../../../../lib/utils/ValidationChecks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../../../lib/components/buttons/Button";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { SpaceCreateRequestDTO } from "../../../models/api/SpaceCreateRequestDTO";
import { SpaceService } from "../../../services/SpaceService";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../../../lib/components/buttons/TextButton";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCall } from "../../../../../common/services/UseServiceCall";

interface IProps {
    onCompleted: () => void;
}

const spacesSvc = new SpaceService();



export function AddSpacePopup(props: IProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const spaceTitleFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true });
    const spaceDescriptionFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true });
    const spaceProjectIdFormControl = useFormControl<string>({ validators: [validateGuid()], enableAutoValidate: true });


    /****************************
     * DATA REQUESTS
     *****************************/

    const addSpace = useServiceCall(spacesSvc.createSpace);

    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    const isButtonDisabled = useMemo(() => {
        return addSpace.isLoading;
    }, [addSpace]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleAddSpaceClicked = useCallback(() => {

        if (!spaceTitleFormControl.validate() || !spaceDescriptionFormControl.validate() || (spaceProjectIdFormControl.value && !spaceProjectIdFormControl.validate()))
            return;

        var requestDTO: SpaceCreateRequestDTO = {
            title: spaceTitleFormControl.value,
            description: spaceDescriptionFormControl.value,
            projectId: spaceProjectIdFormControl.value || undefined,
        };

        addSpace.invoke(requestDTO).then((newSpace) => {
            openPopup(<SuccessPopup>You have created a Space successfully with ID: {newSpace.spaceId}</SuccessPopup>);
            props.onCompleted();
        }).catch((err) => {
            openPopup(<ErrorPopup>{err.code}</ErrorPopup>);
        });
    },[addSpace,spaceTitleFormControl, spaceDescriptionFormControl, spaceProjectIdFormControl, props.onCompleted, openPopup]);

    /****************************
    * CSS & HTML
    *****************************/

    if (addSpace.isLoading) {
        return (
            <PopupContainer className="add-space-popup">
                <Loader />
            </PopupContainer>
        )
    }

    return (
        <PopupContainer className="add-space-popup">

            <h3>Add Space</h3>
            <Spacer mode="vertical" px="16" />

            <FormContainer className="inputs">
                <FormFieldTextInput label="Title" placeholder="Insert a title" formControl={spaceTitleFormControl} />
                <FormFieldTextInput label="Description" placeholder="Insert a description" formControl={spaceDescriptionFormControl} />
                <FormFieldTextInput label="Project ID" placeholder="Insert a GUID" formControl={spaceProjectIdFormControl} />
            </FormContainer>

            <Spacer mode="vertical" px="16" />
            <LineSeparator />

            <FlexLayout className="act-btns" direction='horizontal' horizontalAlign='end' verticalAlign="center"  >

                <TextButton text="Cancel" type="white" onClick={closePopup} />
                <Button text="Add" type="primary" isDisabled={isButtonDisabled}
                    onClick={handleAddSpaceClicked} />

            </FlexLayout>

        </PopupContainer>
    );
}



