import "./SpacesPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { AddSpacePopup } from "./popups/AddSpacePopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { DetailedCard } from "../../../../lib/components/cards/DetailedCard";
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { PlusSVG } from "../../../../lib/assets/icons";
import { Space } from "../../models/Space";
import { SpaceService } from "../../services/SpaceService";
import { useNavigate } from "react-router-dom";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCall } from "../../../../common/services/UseServiceCall";

const emptySpaces: Space[] = [];

const service = new SpaceService();

export function SpacesPage() {
  const navigate = useNavigate();
  const openPopup = usePopup();
  const [spaces, setSpaces] = useState<Space[]>(emptySpaces);

  /****************************
   * DATA REQUESTS
   *****************************/
  const getSpaces = useServiceCall(service.getSpaces);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  useEffect(() => {
    getSpaces.invoke().then((spaces) => setSpaces(spaces));
    console.log("START", process.env.REACT_APP_INLINE_RUNTIME_CHUNK)
  }, []);

  /****************************
   * USER ACTIONS
   *****************************/

   const handleAddSpaceClicked = useCallback(() => {
    openPopup(<AddSpacePopup onCompleted={() => {getSpaces.invoke().then((spaces) => setSpaces(spaces))}} />)
  }, [openPopup, getSpaces, setSpaces]);


  /****************************
   * CSS & HTML
   *****************************/

  const renderSpacesDetailedCards = useMemo(() => {
    return spaces.map((space, idx) => {
      if (space) {
        return (
          <DetailedCard
            key={idx}
            title={space.title}
            description={space.description}
            onClick={() =>
              space.spaceId ? navigate("" +space.spaceId) : null
            }
          />
        );
      }
    });
  }, [spaces]);

  return (
    <PageContainer className="spaces-page">
      <div className="page-header">
        <h1>Spaces</h1>
        <Button
          className="addspace-button"
          icon={<PlusSVG />}
          text="Add Space"
          type="primary"
          onClick={handleAddSpaceClicked}
        />
      </div>
      <div className="cards">{renderSpacesDetailedCards}</div>
    </PageContainer>
  );
}
