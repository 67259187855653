import "./ModuleContentContainer.css";

interface IModuleContentContainerProps {
    children: React.ReactNode;
}

export function ModuleContentContainer(props: IModuleContentContainerProps) {



    return (
        <div className="module-content-container">
            {props.children}
        </div>
    )
}
