import { useCallback, useMemo, useRef, useState } from "react";

import { ActionItem } from "./Tree";
import { CssClassNameBuilder } from "../../utils/CssClassNameBuilder1";
import { IconButton } from "../buttons/IconButton";
import { OptionsSVG } from "../../assets/icons";
import { useOutsideComponentClickTrigger } from "../../hooks/useOutsideComponentClickTrigger";

interface MoreActionsProps<T> {
    actions: ActionItem<T>[];
    item: T;
    highlighted: boolean;
    className?: string;
}

export function MoreActions<T>(props: MoreActionsProps<T>) {

    const componentRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);


    const hasActions = useMemo(() => props.actions.length > 0, [props.actions]);


    useOutsideComponentClickTrigger(componentRef, () => {
        setOpen(false);
    })


    const handleItemClicked = useCallback((action: ActionItem<T>) => {
        action.onClick(props.item)
        setOpen(false);
    }, [props.item, setOpen]);


    const moreActionsIconCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("more-actions-icon")
            .addConditional(props.highlighted, "highlighted")
            .addConditional(props.className, props.className)
            .build();
    }, [props.highlighted])

    return (
        <div ref={componentRef}
            className='more-actions'>
            {hasActions ? <>
                <IconButton icon={(<OptionsSVG />)} className={moreActionsIconCss} onClick={() => setOpen(!open)}/>
                {open ?
                    <div className='more-actions-panel'>
                        {props.actions.map((action, idx) => {
                            return <div key={idx} className='item-other-action' onClick={() => handleItemClicked(action)}>
                                {/* <div className='action-icon'>{action.icon}</div> */}
                                <div className='action-label'>{action.label}</div>
                            </div>
                        })}
                    </div>
                    : null}
            </>
                : null}
        </div>
    )


}
