import "./SpaceOverview.css"

import { useEffect, useState } from "react";

import { PageContainer } from "../../../../../lib/layouts/containers/page/PageContainer";
import { Space } from "../../../models/Space";
import { SpaceService } from "../../../services/SpaceService";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { useParams } from "react-router-dom";
import { useServiceCall } from "../../../../../common/services/UseServiceCall";

const spacesSvc = new SpaceService();

export function SpaceOverview() {

    const { spaceId: spcId } = useParams();
    const [spaceDetails, setSpaceDetails] = useState<Space>();

    /****************************
     * DATA REQUESTS
     *****************************/

    const getSpaceById = useServiceCall(spacesSvc.getById);

    useEffect(() => {
        if (!spcId)
            return;
        getSpaceById.invoke(spcId)
        .then((space) => {
            setSpaceDetails(space);
        }).catch((_) => {
            //navigate to error? 
        });
    }, [spcId]);

    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/
    /****************************
    * USER ACTIONS
    *****************************/
    /****************************
    * CSS & HTML
    *****************************/

    return (

        <PageContainer className="overview-page">
            <Spacer mode="vertical" px="28.5" />
            <h1>Overview</h1>
            <Spacer mode="vertical" px="22" />

            <div className="overview-space-title">
                {spaceDetails?.title}
            </div>

            <Spacer mode="vertical" px="12" />

            {/* TO DISCUSS: SEE HOW THIS WILL BE THIS DISPLAYED */}
            <div className="description-container">
                <div className="column">{spaceDetails?.description}</div>
                <div className="column"></div>
            </div>

        </PageContainer>
    )
}
