import './SystemPopup.css';

import { useCallback, useMemo } from 'react';

import { Button } from '../buttons/Button';
import { CssClassNameBuilder } from '../../utils/CssClassNameBuilder1';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-icon.svg';
import { PopupContainer } from '../../layouts/containers/popup-container/PopupContainer';
import { Spacer } from '../separator/Spacer';
import { useClosePopup } from '../../infrastructure/ui/UIServices';

interface IErrorPopupProps {
    children: React.ReactNode;
    className?: string;
    onDismissClicked?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}


export function ErrorPopup(props: IErrorPopupProps){
    const closePopup = useClosePopup();

    const popupCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("system-popup")
            .add("error-popup")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className]);


    const handleClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        props.onDismissClicked && props.onDismissClicked(ev);
        closePopup();
    }, [props.onDismissClicked]);

    return (
        <PopupContainer className={popupCss}>
            <div className="popup-icon"><ErrorIcon /></div>
            <Spacer px="14" mode="vertical" />
            <div className="popup-header">Error!</div>

            <Spacer px="6" mode="vertical" />
            <div className="popup-content">
                {props.children}
            </div>
            <Spacer px="24" mode="vertical" />
            <div className="popup-actions">
                <Button text="Ok" type="primary" onClick={handleClicked} />
            </div>
        </PopupContainer>
    );
}
