import "./CardContainer.css";

import { CssClassNameBuilder } from "../../../utils/CssClassNameBuilder1";
import { useMemo } from "react";

interface ICardContainerProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export function CardContainer(props: ICardContainerProps) {
  const popupContainerCss = useMemo(() => {
    return CssClassNameBuilder.new()
      .add("card-container")
      .addConditional(props.className, props.className)
      .addConditional(props.onClick, "clickable")
      .build();
  }, [props.className, props.onClick]);

  return (
    <div className={popupContainerCss} onClick={(ev) => {
      ev.stopPropagation();
      props.onClick && props.onClick();
    }}>
    {props.children}
    </div>
  );
}
