import "./IconButtonNavLink.css";

import { useCallback, useEffect, useMemo } from "react";

import { CssClassNameBuilder } from "../../utils/CssClassNameBuilder1";
import { NavLink } from "react-router-dom";

export interface IIconButtonNavLinkProps{
    icon: React.ReactNode;
    navigateTo: string; 
    className?: string;
}

export function IconButtonNavLink(props: IIconButtonNavLinkProps) {

    const IconButtonCss = useCallback((funcProps: {
        isActive: boolean;
        isPending: boolean;
    }) => {
        return new CssClassNameBuilder()
        .add("icon-button-nav-link")
        .addConditional(funcProps.isActive || funcProps.isPending, "selected")
        .addConditional(props.className, props.className)
        .build();
    },[]);


    return (
        <NavLink to={props.navigateTo} className={IconButtonCss}>
            <div className="icon">
                {props.icon}
            </div>
        </NavLink>
    );
}
