import './States.css';

import {ReactComponent as AddNoteSvg} from '../../assets/ilustrations/undraw_add_notes_re_ln36.svg'
import { Button } from '../buttons/Button';
import { CssClassNameBuilder } from "../../utils/CssClassNameBuilder1";
import { Spacer } from '../separator/Spacer';
import { useMemo } from "react";

interface IEmptyStateProps {
    className?: string;
    text?: string;
    buttonLabel?: string;
    onClick?: () => void;

}
export function EmptyState(props: IEmptyStateProps) {

    const css = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("state empty-state")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className])

    const text = useMemo(() => {
        return props.text ?? "Looks like this is empty! Try add some content";
    }, [props.text]);

    return <div className={css}>

        <div className='state-ilustration'>
        <AddNoteSvg/>
        </div>
        <Spacer mode='vertical' px={16} />

        <div className='state-text'>{text}</div>

        <Spacer mode='vertical' px={16} />
        {props.onClick && props.buttonLabel ?

            <Button type='primary' onClick={props.onClick} text={props.buttonLabel} />

        : null}


    </div>
}
