import { EffectCallback, useEffect, useRef } from "react";

export function useOnInit(effect: EffectCallback) : void {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
}



export function useForcedOnInit(effect: EffectCallback) : void {

    const isMounted = useRef(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if(isMounted.current) {
            return;
        }

        isMounted.current = true;
        return effect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
