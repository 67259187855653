import './Tree.css';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton } from '../buttons/IconButton';
import { MoreActions } from './MoreActions';
import { TreeItem } from './TreeItem';
import { useOnInit } from '../../infrastructure/ui/useOnInit';

interface Action {
    label: string;
    onClick: () => void;
    icon?: React.ReactNode;
}

export interface ActionItem<T> {
    label: string;
    onClick: (value: T) => void;
    icon?: React.ReactNode;
}

export interface ItemDefinition<T> {
    render: (v: T) => React.ReactNode;
    icon?: (v: T) => React.ReactNode;
    actions?: (v: T) => ActionItem<T>[];
    children?: (v: T) => T[] | undefined;
    onItemClicked?: (v: T) => void;
}

interface Header {
    label: string;
    actions?: Action[];

}

interface ITreeProps<T> {
    items: T[];
    itemDefinition: ItemDefinition<T>;
    header: Header;
    isItemActive: (item: T) => boolean;
}

export function Tree<T>(props: ITreeProps<T>) {

    const [selected, setSelected] = useState<T>();

    const items = useMemo(() => props.items, [props.items]);

    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    
    /****************************
    * USER INTERACTION
    *****************************/
   
    const handleMainActionClicked = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | undefined, action: Action) => {
        event?.stopPropagation();
        console.log("MainActionClicked");
        action.onClick()
    }, [])

    
    /****************************
    * CSS && HTML
    *****************************/

    const mainAction = useMemo(() => {
        const [action] = props.header.actions || [];

        if (action === undefined) {
            return <div className={"tree-header-main-action"}></div>
        }

        return (
            <IconButton icon={action.icon} className={"tree-header-main-action"} onClick={(e) => handleMainActionClicked(e, action)} />
        )
    }, [props.header.actions])


    return <div className='tree'>
        <div className='tree-header'>
            <div className='tree-header-label'>{props.header.label}</div>
            <div className='tree-header-actions'>
                {mainAction}
                <MoreActions
                    actions={props.header.actions ? props.header.actions.slice(1) : []}
                    item={undefined}
                    highlighted={false}
                    className='header'
                />
            </div>
        </div>

        <div className='tree-body'>
            {items.map((item, index) =>
                <TreeItem
                    key={index}
                    item={item}
                    isItemActive={props.isItemActive}
                    itemDefinition={props.itemDefinition}
                    onSelectedItemChanged={setSelected}
                    selectedItem={selected}
                />)}
        </div>
    </div>
}
