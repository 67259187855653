import MarkdownIt from "markdown-it";
import MarkdownItAnchor from 'markdown-it-anchor';
import MarkdownItHighlight from 'markdown-it-highlightjs';
import MarkdownItToc from 'markdown-it-toc-done-right';
import { attrs } from "@mdit/plugin-attrs";
import { figure } from "@mdit/plugin-figure";
import image_captions from 'markdown-it-figure-caption';
import { mark } from "@mdit/plugin-mark";
import slugify from '@sindresorhus/slugify';
import { tab } from "@mdit/plugin-tab";
import table_captions from 'markdown-it-table-captions';

// import { tasklist } from "@mdit/plugin-tasklist";

export class AstroMarkFactory {



    static CreateEngine() {
        const md =  new MarkdownIt({
            html: true,
            xhtmlOut: true,
            linkify: true,
            typographer: true,
            breaks: true
          })
          .use(MarkdownItHighlight)
     //   .use(MarkdownItAnchor, {slugify: (s:string) => slugify(s), permalink: true, permalinkBefore: true, permalinkSymbol: '§'})
          .use(MarkdownItAnchor, {slugify: (s:string) => slugify(s), permalink: MarkdownItAnchor.permalink.headerLink({ safariReaderFix: true })})
          .use(MarkdownItToc,{slugify: (s:string) => slugify(s)})
          .use(tab, {name:'tabs'})
          .use(mark)
          .use(attrs)
          .use(figure)
          .use(table_captions)



        //   var existingLinkRule = md.renderer.rules.link_open;
        //   md.renderer.rules.link_open = (tokens, idx, opt, env, self) => {

        //     let link = tokens[idx] as any;
        //     let href = ((link.attrs as Array<string[]>).find(a => a[0] === "href") ?? ["", null])[1] ;
        //     let title = "test";
        //     console.log(link, href);
        //     if(href?.includes("https://ark.orbitalwatch.dev")){
        //         //var titleHtML = link.title ? (' title="' + md.utils.escapeHtml(link.title) + '"') : '';
        //         return `<div><a href="${md.utils.escapeHtml(href)}" target="_blank"> ${"test"} </a></div>`;
        //     }

        //     else return existingLinkRule?.(tokens,idx, opt, env, self) || "";
        // };



          return md;
    }
}
