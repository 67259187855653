import {useCallback, useEffect, useMemo, useState} from 'react';

import { boolean } from 'yargs';

export type Optional<T> = T | undefined;
export type ErrorMessage = string;
export type ValidatorFunction<T> = (value: T) => ErrorMessage | null


function requiredValidator<T>(): ValidatorFunction<T> {
    return (value: T|undefined) => {
        if(!value) {
            return "This field is required!";
        }
        return null;
    }
}


export interface IFormControl<T> {
    value: Optional<T>;
    setValue: React.Dispatch<React.SetStateAction<Optional<T>>>;
    errorMessages: string[];
    isValid: boolean;
    isDisabled: boolean;
    setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    hasErrors: boolean;
    validate: () => boolean;

}

export interface IUserFormControlParameters<T> {
    initialValue?: T;
    isDisabled?: boolean;
    validators?: ValidatorFunction<Optional<T>>[];
    enableAutoValidate?: boolean
}



export function useFormControl<T>(options: IUserFormControlParameters<T>) :IFormControl<T> {
    const [value, setValue] = useState<Optional<T>>(options?.initialValue);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const [isDirty, setIsDirty] = useState(false);

    const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);

    const validate = useCallback(() => {
        let errors: ErrorMessage[] = [];

        options?.validators?.forEach(validator => {
            let error = validator(value);
            if(error) {
                errors.push(error);
            }
        })
        setErrorMessages(errors);
        setIsValid(!errors.length);
        return !errors.length;

    }, [options.validators, value, setErrorMessages, setIsValid])

    useEffect(() => {
        if(value && !isDirty) {
            setIsDirty(true);
            validate();
        }

        if(options?.enableAutoValidate && isDirty) {
            validate();
        }
    }, [value, options?.enableAutoValidate])


    const hasErrors = useMemo(() => errorMessages && errorMessages.length ? true : false, [errorMessages]);

    return {value, setValue, isDisabled, setIsDisabled, hasErrors, errorMessages, isValid, validate} // TODO: should be this be put in a memo
}
