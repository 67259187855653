import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { EmbededDocumentationPage } from "../features/documentation/pages/documentation-home-page/EmbededDocumentationPage";
import { ModuleContentContainer } from '../lib/layouts/containers/content/ModuleContentContainer';
import { NavBar } from "../lib/components/nav-bar/NavBar";
import { SpacesModule } from "./SpacesModule";
import { SpacesPage } from "../features/spaces/pages/spaces-home-page/SpacesPage";

export function AppRoutingModule() {



  return (
    <>
      <NavBar />

      <ModuleContentContainer>
        <Routes>
          <Route path="/spaces" element={<SpacesPage />} />
          <Route path="/spaces/:spaceId/*" element={<SpacesModule />} />

          <Route path="*" element={<Navigate to="/notfound"/>}/>
        </Routes>
      </ModuleContentContainer>

    </>
  );
}
