import "./MediaPopup.css";
import "react-markdown-editor-lite/lib/index.css";

import { CloseSVG, FolderSVG } from "../../../../../lib/assets/icons";
import { DefaultExtensionType, FileIcon, defaultStyles } from "react-file-icon"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import {
  useClosePopup,
  usePopup,
} from "../../../../../lib/infrastructure/ui/UIServices";

import { CssClassNameBuilder } from "../../../../../lib/utils/CssClassNameBuilder1";
import { FileUploadButton } from "../../../../../lib/components/file/FileUploadButton";
import { IconButton } from "../../../../../lib/components/buttons/IconButton";
import { LineSeparator } from "../../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../../lib/components/loader/Loader";
import { PageFile } from "../../../models/PageFile";
import { PageFilesService } from "../../../services/PageFilesService";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { useServiceCallPro } from "../../../../../common/services/UseServiceCall";

export interface IMediaPopupProps {
  pageId: string;
  spaceId: string;
  handleFileUpload: (files: File[]) => void
}

var pageFilesSvc = new PageFilesService();

const getExtension = (fileUrl: string): DefaultExtensionType => {
  const tokens = fileUrl.split(".");

  if (tokens.length === 0) return "3dm" as DefaultExtensionType;

  return tokens.pop() as DefaultExtensionType;
}

export const MediaPopup = forwardRef((props: IMediaPopupProps, ref) => {

  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const [media, setMedia] = useState<PageFile[]>([]);
  const [mediaCount, setMediaCount] = useState<number>(0);
  const [isUploadingFile, setIsUploading] = useState(false);

  const getFilesCall = useServiceCallPro(pageFilesSvc.getFiles);

  /****************************
   * DATA REQUESTS
   *****************************/

  const getFiles = useCallback(() => {
    if (props.spaceId === undefined || props.pageId === undefined) return;

    getFilesCall
      .invoke(props.spaceId, props.pageId)
      .then(res => {
        setMedia(res.files)
        setMediaCount(res.pageSize);
      })
  }, [getFilesCall.invoke, props.spaceId, props.pageId, setMedia, setMediaCount])


  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/
  useEffect(() => {
    getFiles()
  }, [props.spaceId, props.pageId]);


  useImperativeHandle(ref, () => ({
    setIsUploading(is: boolean) {
      setIsUploading(is)
      if (is === false) { closePopup() }
    },
  }), [setIsUploading, getFiles]);

  /****************************
   * USER ACTIONS
   *****************************/
  const handleFileClick = useCallback((file: PageFile) => {
    navigator.clipboard.writeText(`![${file.name}](${file.url})`);
    closePopup();
  }, [closePopup])

  /****************************
   * CSS & HTML
   *****************************/
  const popUpContainerCss = useMemo(() => {
    return CssClassNameBuilder.new()
      .add("media-popup-container")
      .build();
  }, []);

  return (
    <PopupContainer className="media-popup">
      <div className="media-popup-header">
        <h3>Media</h3>
        <IconButton icon={<CloseSVG />} onClick={closePopup} />
      </div>
      <LineSeparator />

      <div className={popUpContainerCss}>
        {isUploadingFile || getFilesCall.isLoading ? <Loader /> :
          (<div className="media-files">
            <FileUploadButton
              text={"Click here to updoad file or drop here"}
              type="secondary"
              onFileSelected={(file: File) => {
                const temp: File[] = [];
                file && temp.push(file);
                props?.handleFileUpload(temp);
              }}
              className="media-files-drop-here"
            />
            {media.map(file => (<div key={encodeURI(file.url)} className="media-file" onClick={() => handleFileClick(file)}>
              {file.thumbnailUrl ?
                <img src={file.thumbnailUrl} /> :
                <FileIcon
                  extension={getExtension(file.url)}
                  {...defaultStyles[getExtension(file.url)]}
                />
              }
              <div className="media-file-label">{file.name}</div>
              {/* <div className="media-file-date">{file.uploadDate}</div> */}

            </div>))}
          </div>)
        }

      </div>
    </PopupContainer>
  );
})
