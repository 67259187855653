import "./DocumentationPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AddState } from "../../../../lib/components/states/AddState";
import { AreaContainer } from "../../../../lib/layouts/containers/AreaContainer";
import { Button } from "../../../../lib/components/buttons/Button";
import { EditPagePopup } from "./EditPagePopup";
import { GetPageResponse } from "../../models/services/GetPageResponse";
import { Loader } from "../../../../lib/components/loader/Loader";
import { Page } from "../../models/Page";
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { PageContentChangedEvent } from "../../../../common/events/PageContentChangedEvent";
import { PageContentRenderer } from "./PageContentRenderer";
import { PageHeader } from './PageHeader';
import { PageService } from "../../services/PageService";
import { Spacer } from '../../../../lib/components/separator/Spacer';
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../common/services/UseServiceCall";

var pageSvc = new PageService();


const mapGetPageResponseToPage = (page: GetPageResponse): Page => ({
  pageId: page.pageId,
  title: page.title,
  pageContent: page.pageContent,
  dateCreated: page.dateCreated,
  dateModified: page.dateModified,
  modifiedBy: page.modifiedBy
});


export function EmbededDocumentationPage() {


    const openPopup = usePopup();
    const navigate = useNavigate();
    const { spaceId, pageId } = useParams();

    const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
    const [pageInfo, setPageInfo] = useState<Page>();

    const getPageByIdCall = useServiceCallPro(pageSvc.getPageById);



    /****************************
    * DATA REQUESTS
    *****************************/

    const getPage = useCallback(() => {
      if ((!pageId) || !spaceId) return;

      getPageByIdCall.invoke(spaceId, pageId).then((response) => {
        setPageInfo(mapGetPageResponseToPage(response));
      });
    }, [getPageByIdCall, pageId, spaceId, setPageInfo]);

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
      getPage();
    }, [pageId, spaceId]);



    /****************************
     * USER ACTIONS
     *****************************/

    const handleOpenInArkClicked = useCallback(()=>{
      window.open(`https://ark.orbitalwatch.dev/spaces/${spaceId}/pages/${pageId}`, "_blank");

    }, [pageId, spaceId]);

    const handleRefreshClicked = useCallback(()=>{
      window.location.reload();

    }, []);


    /****************************
     * CSS & HTML
     *****************************/


    if(getPageByIdCall.isLoading) {
      return (
        <div className="page-area">
          <div className="page-content scroll-parent">
            <PageContainer>
              <AreaContainer>
                <Loader />
              </AreaContainer>
            </PageContainer>
          </div>
        </div>
      );
    }

    if (!pageId || !pageInfo || !spaceId) {
      return (
        <div className="page-content">
          <AddState text="Looks like this is empty! Try add a new page to this space!" />
        </div>
      );
    }



    return (

      <div className="page-area">

        <div className="page-content scroll-parent">
          <PageContainer className="page-content-container panel-1">
            <div className="page-content-body">
              <div className="page-content-wrapper">

                <PageHeader
                  title={pageInfo.title}
                  author={pageInfo.modifiedBy}
                  date={pageInfo.dateModified}>
                    <Button
                      className="addspace-button"
                      text="Open in Ark"
                      type="primary"
                      onClick={handleOpenInArkClicked}
                    />

                  <Button
                      className="addspace-button"
                      text="Refresh"
                      type="primary"
                      onClick={handleRefreshClicked}
                    />
                </PageHeader>

                <Spacer px={40} mode='vertical' />

                <PageContentRenderer markdownContent={pageInfo.pageContent} emptyPageOnClick={() => {}} hideToc={false}/>
              </div>
            </div>


          </PageContainer>
        </div>

      </div>



    );

}
