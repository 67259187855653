import "./TextButton.css";

import { useCallback, useMemo } from 'react';

import { CssClassNameBuilder } from '../../utils/CssClassNameBuilder1';

export interface ITextButtonProps {
    text: string;
    type?: 'primary' | 'white';
    isDisabled?: boolean;
    className?: string;
    icon?: React.ReactNode;
    onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}


export function TextButton(props: ITextButtonProps){


    const textButtonCss = useMemo(() => {
        let type = props.type ?? 'primary';
        return CssClassNameBuilder.new()
            .add("text-button")
            .addConditional(props.isDisabled, "disabled")
            .addConditional(type,type)
            .addConditional(props.className, props.className)
            .build();
    }, [props.className, props.type, props.isDisabled]);


    const handleClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        if(props.isDisabled) return;
        props.onClick && props.onClick(ev);
    }, [props.onClick, props.isDisabled]);

    return (

        <span className={textButtonCss} onClick={handleClicked}>
            {props.text}
            {props.icon ? <span className="text-button-icon">{props.icon}</span> : null}
        </span>
    );
}
