import "./HorizontalResizer.css";

import { useCallback, useEffect, useRef } from "react";

interface IHorizontalResizerProps {
    leftAreaRef: React.RefObject<HTMLDivElement>;
    leftArea: React.ReactNode;
    rightAreaRef: React.RefObject<HTMLDivElement>;
    rightArea: React.ReactNode;
}

export function HorizontalResizer(props: IHorizontalResizerProps) {

    const resizerRef = useRef<HTMLDivElement>(null);

    const dragElement = useCallback((element: any) => {
        var mouseDownInfo: { event: any; leftWidth: any; rightWidth: any; offsetLeft: any; offsetTop?: any; };

        const left = props.leftAreaRef.current;
        const right = props.rightAreaRef.current;

        if (element) element.onmousedown = onMouseDown;

        function onMouseDown(event: any) {
            mouseDownInfo = {
                event,
                offsetLeft: element.offsetLeft,
                offsetTop: element.offsetTop,
                leftWidth: left?.offsetWidth,
                rightWidth: right?.offsetWidth
            };

            document.onmousemove = onMouseMove;
            document.onmouseup = () => {
                document.onmousemove = document.onmouseup = null;
            }
        }

        function onMouseMove(event: any) {
            var delta = {
                x: event.clientX - mouseDownInfo.event.clientX,
                y: event.clientY - mouseDownInfo.event.clientY
            };

            // Prevent negative-sized elements
            delta.x = Math.min(Math.max(delta.x, -mouseDownInfo.leftWidth), mouseDownInfo.rightWidth);
            element.style.left = mouseDownInfo.offsetLeft + delta.x + "px";

            if (left && right) {
                left.style.width = (mouseDownInfo.leftWidth + delta.x) + "px";
                right.style.width = (mouseDownInfo.rightWidth - delta.x) + "px";
            }
        }

        return () => { if (element) element.onmousedown = null }
    }, [props.leftAreaRef, props.rightAreaRef])

    useEffect(() => {
        return dragElement(resizerRef.current);
    }, [dragElement])

    return (
        <div className="resize-container">
            <div className="resize-content">
                {props.leftArea}
                <div ref={resizerRef} className="horizontal-resizer" />
                {props.rightArea}
            </div>
        </div>
    );
}
