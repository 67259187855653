import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CreateCommentRequest } from "../models/services/CreateCommentRequest";
import { GetPageCommentsResponse } from "../models/services/GetPageComments";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { UpdateCommentRequest } from "../models/services/UpdateCommentRequest";
import { UrlUtility } from "../../../lib/utils/UrlUtility";

const Route = (path: string) => {
  return UrlUtility.join(
    ConfigurationProvider.getConfiguration().App.BackendUrl,
    path
  );
};

export class CommentService {
  async createComment(
    request: CreateCommentRequest,
    spaceId: string,
    pageId: string
  ): Promise<string> {
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/Spaces/${spaceId}/Pages/${pageId}/Comments`),
      data: request,
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  async getPageComments(
    spaceId: string,
    pageId: string
  ): Promise<GetPageCommentsResponse> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/Spaces/${spaceId}/Pages/${pageId}/Comments`),
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  async editComment(
    request: UpdateCommentRequest,
    spaceId: string,
    pageId: string,
    commentId: string
  ): Promise<void> {
    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(
        `/api/Spaces/${spaceId}/Pages/${pageId}/Comments/${commentId}`
      ),
      data: request,
    })
      .then((_) => {})
      .catch((err) => {
        throw err;
      });
  }

  async deleteComment(
    spaceId: string,
    pageId: string,
    commentId: string
  ): Promise<Comment> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(
        `/api/Spaces/${spaceId}/Pages/${pageId}/Comments/${commentId}`
      ),
    })
      .then((res) => {
        console.log("Comment", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
}
