import "./DropZonePopup.css";
import "react-markdown-editor-lite/lib/index.css";

import { CloseSVG, FolderSVG } from "../../../../../lib/assets/icons";
import { DefaultExtensionType, FileIcon, defaultStyles } from "react-file-icon"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import {
    useClosePopup,
    usePopup,
} from "../../../../../lib/infrastructure/ui/UIServices";

import { CssClassNameBuilder } from "../../../../../lib/utils/CssClassNameBuilder1";
import { IconButton } from "../../../../../lib/components/buttons/IconButton";
import { LineSeparator } from "../../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../../lib/components/loader/Loader";
import { PageFile } from "../../../models/PageFile";
import { PageFilesService } from "../../../services/PageFilesService";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { useServiceCallPro } from "../../../../../common/services/UseServiceCall";

export interface IDropZonePopupProps {
    pageId: string;
    spaceId: string;
}

export const DropZonePopup = forwardRef((props: IDropZonePopupProps, ref) => {

    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const [isUploadingFile, setIsUploading] = useState(false);

    useImperativeHandle(ref, () => ({
        setIsUploading(is: boolean) {
            setIsUploading(is)
            if (is === false) { closePopup() }
        },
    }), [setIsUploading, closePopup]);

    /****************************
     * CSS & HTML
     *****************************/
    const popUpContainerCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("dropzone-popup-container")
            .build();
    }, []);

    return (
        <PopupContainer className="dropzone-popup">
            <div className="dropzone-popup-header">
                <h3>Drop File</h3>
                <IconButton icon={<CloseSVG />} onClick={closePopup} />
            </div>
            <LineSeparator />

            <div className={popUpContainerCss}>
                {isUploadingFile ? <Loader /> : <div className="dropzone-files"> Drop your files here </div>}
            </div>
        </PopupContainer>
    );
})
