import './PermissionPage.css';

import { PageContainer } from '../../../../lib/layouts/containers/page/PageContainer';

export function PermissionPage() {
    return (
        <PageContainer className="edit-permission-page">
            <div>TODO</div>
        </PageContainer>
    )
}
