import { CancelToken } from "axios";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EditPageRequestFromPopupDTO } from "../pages/documentation-home-page/EditPagePopup";
import { GetPageResponse } from "../models/services/GetPageResponse";
import { GetPagesResponse } from "../models/services/GetPagesResponse";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { MoveInstructionDTO } from "../models/MoveInstructionDTO";
import { PageCreateRequestDTO } from "../models/services/PageCreateRequestDTO";
import { PageEditRequestDTO } from "../models/services/PageEditRequestDTO";
import { PageHistoryResponseDTO } from "../models/services/PageHistoryResponseDTO";
import { UrlUtility } from "../../../lib/utils/UrlUtility";
import { cancelationToken } from '../../../lib/infrastructure/http/AddAxiosInterceptor';

const Route = (path: string) => {
  return UrlUtility.join(
    ConfigurationProvider.getConfiguration().App.BackendUrl,
    path
  );
};

export class PageService {
  async getPages(spaceId: string): Promise<GetPagesResponse> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/Spaces/${spaceId}/Pages`),
    })
      .then((res) => res.data)
      .catch((err) => { throw err; });
  }

  async getPageById(spaceId: string, pageId: string, cancelationToken?: CancelToken): Promise<GetPageResponse> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/Spaces/${spaceId}/Pages/${pageId}`),
      cancelToken: cancelationToken
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        //throw err;
      });
  }

  async createPage(
    title: string,
    content: string | undefined,
    spaceId: string,
    pageId: string | undefined
  ): Promise<string> {

    var requestDTO: PageCreateRequestDTO = {
      content: content,
      title: title,
      pageId: pageId
    };


    return HttpClient.sessionRequest<GetPageResponse>({
      method: "POST",
      url: Route(`/api/Spaces/${spaceId}/Pages`),
      data: requestDTO,
    })
      .then((res) => {
        console.log("Spaces", res.data.title);
        return res.data.title;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async movePage(
    spaceId: number,
    pageId: number,
    instruction: MoveInstructionDTO
  ): Promise<string> {
    // /api/Spaces/${spaceId}/Pages/${pageId}/Move?instruction=${instruction}
    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/Spaces/${spaceId}/Pages/${pageId}/Move`),
      params: instruction,
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async editPage(request: EditPageRequestFromPopupDTO): Promise<void> {
    var requestDTO: PageEditRequestDTO = {
      content: request.content,
      title: request.title,
    };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/Spaces/${request.spaceId}/Pages/${request.pageId}`),
      data: requestDTO,
    })
      .then((_) => {})
      .catch((err) => {
        throw err;
      });
  }

  getPageHistory(
    spaceId: string,
    pageId: string
  ): Promise<PageHistoryResponseDTO[]> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/Spaces/${spaceId}/Pages/${pageId}/History`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async deletePage(spaceId: number, pageId: number): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/Spaces/${spaceId}/Pages/${pageId}`),
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }
}
