import { AMTocNode } from "../../../../features/documentation/utils/astro-mark/AMTableOfContents";
import { TocNode } from "./TocNode";

export interface ITocProps {
    items: AMTocNode[];

  }
  export function Toc(props: ITocProps) {

    return (
      <nav className="table-of-contents">
        <ol>
          {props.items.map((i, idx) => <TocNode key={idx} node={i} />)}
        </ol>
      </nav>
    )
  }
