import "./IconButton.css";

import { useCallback, useEffect, useMemo } from "react";

import { CssClassNameBuilder } from "../../utils/CssClassNameBuilder1";
import { NavLink } from "react-router-dom";

export interface IIconButtonProps{
    icon: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | undefined) => void; 
    size?: number;
    className?: string;
    isActive?: boolean;
}

export function IconButton(props: IIconButtonProps) {

    const IconButtonCss = useMemo(() => {
        return new CssClassNameBuilder()
        .add("icon-button")
        .addConditional(props.isActive, "selected")
        .addConditional(props.className, props.className)
        .build();
    },[props.isActive, props.className]);

    return (
        <a onClick={props.onClick} className={IconButtonCss}>
            <div className="icon">
                {props.icon}
            </div>
        </a>
    );
}
