export class PageChangedEvent {
    static firePageChanged() {
       let event = new CustomEvent<void>("page-changed");
       window.dispatchEvent(event);
    }
   
    static attachHandler(handler: EventListener){
       window.addEventListener("page-changed", handler);
    }
   
    static dettachHandler(handler: EventListener){
       window.removeEventListener("page-changed", handler);
    }
   }